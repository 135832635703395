import { AxiosInstance } from 'axios';
import { IntersellApiClientInstance } from 'reseller/interfaces';
import { DataStoreFilter } from 'shared/features/data-store/data-store.module';
import { ApiFetchDetailsParams } from 'shared/api/interfaces';
import {
  CreateTicketFields,
  CreateTicketMessageFields,
  TicketInterface,
  TicketMessageInterface,
  UnreadInterface,
} from 'shared/interfaces/support';

class SupportService implements IntersellApiClientInstance {
  api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async fetch(params: DataStoreFilter): Promise<TicketInterface> {
    const { data } = await this.api.get('/support', {
      params,
    });

    return data.data;
  }

  async fetchUnread(): Promise<UnreadInterface> {
    const { data } = await this.api.get('/support/unread');

    return data.data;
  }

  async fetchDetails(
    params: ApiFetchDetailsParams,
  ): Promise<TicketMessageInterface[]> {
    const { data } = await this.api.get(`/support/${params.id}/messages`);

    return data.data;
  }

  async create(params: CreateTicketFields): Promise<TicketInterface> {
    const { data } = await this.api.post(`/support`, params);

    return data.data;
  }

  async createMessage(
    params: CreateTicketMessageFields,
  ): Promise<TicketMessageInterface> {
    const { data } = await this.api.post(`/support/message`, params);

    return data.data;
  }
}

export default SupportService;
