import { AxiosInstance } from 'axios';
import { UserDetailsInterface } from 'reseller/interfaces/user';

class AppUserService {
  api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async fetchUserInfo(): Promise<UserDetailsInterface> {
    const { data } = await this.api.get('/users/me');

    return data.data;
  }
}

export default AppUserService;
