import { DataStore } from 'shared/features/data-store/data-store.module';
import { extendObservable, reaction, runInAction } from 'mobx';
import { TicketInterface } from 'reseller/interfaces/resellerSupport';
import { CStoreCreateFields } from 'shared/features/data-store/creatable.store';
import { getErrors } from 'shared/api';
import SharedRootStore from 'shared/store/SharedRootStore';

class SupportStore extends DataStore {
  override list: TicketInterface[] = [];

  unreadIdsTickets: number[] = [];

  unreadFetchTime = 15000;

  constructor(rootStore: SharedRootStore) {
    super({
      rootStore,
      apiClient: rootStore.apiClient.support,
    });

    extendObservable(this, {
      unreadIdsTickets: this.unreadIdsTickets,
    });

    reaction(
      () => this.unreadIdsTickets,
      async () => {
        this.rootStore.notificationsStore.addRouteNotification(
          `${this.rootStore.appUserStore.getUserDomain}/support`,
          this.unreadIdsTickets?.length || 0,
        );
      },
    );

    reaction(
      () => this.rootStore.appUserStore.userInfoRecieved,
      (userInfoRecieved) => {
        if (userInfoRecieved) {
          this.getUnread();
          setInterval(this.getUnread, this.unreadFetchTime);
        }
      },
    );

    this.getUnread = this.getUnread.bind(this);
  }

  setUnread(ids: number[] | []): void {
    runInAction(async () => {
      this.unreadIdsTickets = ids;
    });
  }

  async getUnread(): Promise<void> {
    const result = await this.apiClient.fetchUnread();

    if (result.ids) {
      this.setUnread(result.ids);
    }
  }

  override async create(params: CStoreCreateFields): Promise<void> {
    runInAction(() => {
      this.createErrors = {};
      this.isCreating = true;
      this.isCreated = false;
      this.createFields = undefined;
    });

    try {
      this.createFields = this.prepareCreateParams(params);

      await this.apiClient.create(this.createFields);
      this.fetch();

      runInAction(() => {
        this.isCreated = true;
        this.createFields = undefined;
      });
    } catch (err: any) {
      runInAction(() => {
        this.createErrors = getErrors(err);
      });
    } finally {
      runInAction(() => {
        this.isCreating = false;
      });
    }
  }

  async createMessage(params: CStoreCreateFields): Promise<void> {
    runInAction(() => {
      this.createErrors = {};
      this.isCreating = true;
      this.isCreated = false;
      this.createFields = undefined;
    });

    try {
      this.createFields = this.prepareCreateParams(params);

      await this.apiClient.createMessage(this.createFields);

      runInAction(() => {
        this.isCreated = true;
        this.createFields = undefined;
      });
    } catch (err: any) {
      runInAction(() => {
        this.createErrors = getErrors(err);
      });
    } finally {
      runInAction(() => {
        this.isCreating = false;
      });
    }
  }
}

export default SupportStore;
