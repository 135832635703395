import { useLocalObservable } from 'mobx-react-lite';
import { createContext, ReactElement, useContext } from 'react';
import {
  CreateStoreProps,
  DataStoreProviderProps,
} from 'shared/features/data-store/data-store.module';

function createStore({ dataStore }: CreateStoreProps) {
  return {
    dataStore,
  };
}

type ptStore = ReturnType<typeof createStore>;

const DataStoreContext = createContext<ptStore | null>(null);

export const DataStoreContextProvider = ({
  dataStore,
  children,
  defaultFilterParams,
}: DataStoreProviderProps): ReactElement => {
  const store = useLocalObservable(() => ({
    dataStore,
    defaultFilterParams,
  }));

  return (
    <DataStoreContext.Provider value={store}>
      {children}
    </DataStoreContext.Provider>
  );
};

export const useDataStoreContext = (): any => {
  const store = useContext(DataStoreContext);

  if (!store) {
    throw new Error(
      'useDataStoreContext must be used within a DataStoreContextProvider.',
    );
  }
  return store;
};
