import { ThemeObject } from 'reseller/interfaces';
import { THEMES } from '../constants';

const darkTheme: ThemeObject = {
  name: THEMES.DARK,
  rules: new Map<string, string>(),
};

darkTheme.rules.set('--main-background', '#868686');
darkTheme.rules.set('--semi-background', '#939393');
darkTheme.rules.set('--main-dark', '#353f53');
darkTheme.rules.set('--main-dark-01', 'rgba(53, 63,83, .1)');
darkTheme.rules.set('--main-dark-border', '#3e4c5d');
darkTheme.rules.set('--hover-blue', '#03a9f4');
darkTheme.rules.set('--menu-active', '#03a124');
darkTheme.rules.set('--main-text', '#ffffff');
darkTheme.rules.set('--dark-text-09', 'rgba(255,255,255,.9)');
darkTheme.rules.set('--dark-text-03', 'rgba(255,255,255,.3)');
darkTheme.rules.set('--dark-text-01', 'rgba(255,255,255,.1)');

export default darkTheme;
