// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { runInAction, makeAutoObservable, computed, action } from 'mobx';
import BaseApiClient, { getErrorMessage } from 'shared/api';
import { ROLES } from 'shared/lib/constants';
import { StoreInterface } from '../../store/interfaces';

export interface UserDetailsInterface {
  id: number;
  email: string;
  name: string;
  created_at: string;
  updated_at: string;
  roles: string[];
  permissions: {
    direct: string[];
    via_roles: string[];
  };
  merchants?: any;
  merchant_groups: any[];
  changes_description?: string;
  status?: number;
}

class AppUserStore implements StoreInterface {
  apiClient: BaseApiClient;

  user?: UserDetailsInterface;

  isFetching = false;

  userInfoRecieved = false;

  fetchingError?: string;

  constructor(rootStore: StoreInterface) {
    makeAutoObservable(this);
    this.apiClient = rootStore.apiClient;
  }

  async fetchUserInfo(): Promise<void> {
    runInAction(() => {
      this.fetchingError = undefined;
      this.isFetching = true;
    });

    try {
      const result = await this.apiClient.user.fetchUserInfo();
      runInAction(() => {
        this.user = result;
        this.userInfoRecieved = true;
      });
    } catch (err: any) {
      runInAction(() => {
        this.fetchingError = getErrorMessage(err);
      });
    } finally {
      runInAction(() => {
        this.isFetching = false;
      });
    }
  }

  @computed get isRegistered(): boolean {
    return Boolean(this.user?.roles.includes(ROLES.REGISTERED_ROLE));
  }

  @computed get isReseller(): boolean {
    return Boolean(this.user?.roles.includes(ROLES.RESELLER_ROLE));
  }

  @computed get isAdmin(): boolean {
    return Boolean(this.user?.roles.includes(ROLES.SUPER_ADMIN));
  }

  @computed get getUserDomain(): string {
    if (this.isAdmin) {
      return '/admin';
    }
    if (this.isRegistered) {
      return '/registered';
    }
    if (this.isReseller) {
      return '/reseller';
    }

    return '';
  }

  @action
  resetUser(): void {
    this.user = undefined;
    this.isFetching = false;
    this.fetchingError = undefined;
  }
}

export default AppUserStore;
