import { SimpleStoreInterface } from 'reseller/interfaces';
import { runInAction, makeAutoObservable } from 'mobx';
import { getErrorMessage } from 'shared/api';
import { BalanceHistoryType } from '../dictionaries.module';

class BalanceHistoryTypesStore
  implements SimpleStoreInterface<BalanceHistoryType>
{
  fetchMethod: () => any;

  isFetching = false;

  isReceived = false;

  fetchingError?: string;

  list = [] as BalanceHistoryType[];

  constructor(fetchMethod: () => any) {
    makeAutoObservable(this);
    this.fetchMethod = fetchMethod;
  }

  async getList(): Promise<BalanceHistoryType[]> {
    if (!this.isFetching && !this.isReceived) {
      await this.fetch();
    }

    return this.list || [];
  }

  async fetch(): Promise<BalanceHistoryType[] | void> {
    runInAction(() => {
      this.fetchingError = undefined;
      this.isFetching = true;
      this.isReceived = false;
    });

    try {
      const { data } = await this.fetchMethod();

      const tempArr = [] as BalanceHistoryType[];
      Object.keys(data).forEach((key: string): void => {
        tempArr.push({
          id: parseInt(key, 10),
          name: data[key],
        });
      });

      runInAction(() => {
        this.isFetching = false;
        this.list = tempArr;
        this.isReceived = true;
      });
    } catch (err: any) {
      runInAction(() => {
        this.fetchingError = getErrorMessage(err);
        this.isReceived = false;
        this.isFetching = false;
      });
    }
  }
}

export default BalanceHistoryTypesStore;
