import { AxiosInstance } from 'axios';
import { ApiFetchDetailsParams } from 'shared/api/interfaces';
import { DataStoreFilter } from 'shared/features/data-store/data-store.module';
import { InvoiceInterface, AllInvoiceResponse, ApiCreateInvoiceParams } from './reseller-invoices.module';

class ResellerInvoicesService {
  api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async fetch(params: DataStoreFilter): Promise<AllInvoiceResponse> {
    const { data } = await this.api.get('invoices', { params });

    return data.data;
  }

  async fetchDetails(params: ApiFetchDetailsParams): Promise<InvoiceInterface> {
    const { data } = await this.api.get(`/invoices/${params.id}`);

    return data.data;
  }

  async createInvoice(params: ApiCreateInvoiceParams): Promise<any> {
    const { data } = await this.api.post('/invoices', params);

    return data.data;
  }

  async continueInvoice(id: number): Promise<any> {
    const { data } = await this.api.post(`/invoices/${id}`);

    return data.data;
  }
}

export default ResellerInvoicesService;
