import { ReactElement } from 'react';

const InAppErrorBoundary = (): ReactElement => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100vw',
      height: '50vw',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <h3>Something went wrong...</h3>
    <p>Please contact our support team.</p>
  </div>
);

export default InAppErrorBoundary;
