import { StoreInterface } from 'reseller/interfaces';
import { makeAutoObservable, runInAction } from 'mobx';
import useLocalStorage from 'shared/lib/useLocalStorage';
import { UpgradeNotificationInterface } from './upgrade-notifications.module';

class UpgradeNotificationsStore implements StoreInterface {
  seen: string[] = [];

  list: UpgradeNotificationInterface[] = [];

  constructor() {
    makeAutoObservable(this);
    const { getLocalStorageValue } = useLocalStorage();

    this.seen =
      JSON.parse(getLocalStorageValue('seen_upgrade_notifications') || '[]') ||
      [];

    this.setAsSeen = this.setAsSeen.bind(this);
    this.addNewFeatureNotification = this.addNewFeatureNotification.bind(this);
  }

  setAsSeen(itemId: string | string[]): void {
    const { setLocalStorageValue } = useLocalStorage();
    runInAction(() => {
      if (Array.isArray(itemId)) {
        this.seen = [...this.seen, ...itemId];
      } else {
        this.seen.push(itemId);
      }
      this.list = this.list.filter((el) => !this.seen.includes(el.id));
    });

    setLocalStorageValue('seen_upgrade_notifications', this.seen);
  }

  async addNewFeatureNotification(
    item: UpgradeNotificationInterface,
  ): Promise<void> {
    await setTimeout(() => {
      if (this.seen.includes(item.id)) {
        return;
      }

      if (!this.list.find((el) => el.id === item.id)) {
        runInAction(() => {
          this.list = [...this.list, item];
        });
      }
    }, 1000);
  }
}

export default UpgradeNotificationsStore;
