import { extendObservable, reaction, runInAction } from 'mobx';

import {
  getErrorMessage,
  getErrorMessageWithCode,
  getErrors,
} from 'shared/api';
import {
  DataStoreFilter,
  DataStoreItemInterface,
  CStoreCreateFields,
  UStoreUpdateFields,
} from 'shared/features/data-store/data-store.module';
import { ApiInstance, ErrorInterface } from 'shared/api/interfaces';
import UpdatableStore, { UStoreObject } from './updatable.store';
import CreatableStore from './creatable.store';
import RemovableStore, { RStoreRemoveFields } from './removable.store';
import ReadOnlyDataStore from './read-only.store';
import { AbstractStoreInstance } from '../../store/interfaces';

export interface DataStoreParams<S> {
  rootStore: S;
  apiClient: ApiInstance;
}

abstract class DataStore<S = AbstractStoreInstance>
  extends ReadOnlyDataStore<S>
  implements CreatableStore, UpdatableStore, RemovableStore
{
  isUpdating?: UStoreObject;

  isUpdated = false;

  override isReceived = false;

  updateErrors?: ErrorInterface | Record<string, never>;

  isCreating = false;

  isCreated = false;

  createErrors?: ErrorInterface;

  createFields?: CStoreCreateFields;

  isRemoving = false;

  isRemoved = false;

  removeErrors?: string;

  isSearching = false;

  searchResults?: DataStoreItemInterface[];

  searchingError?: ErrorInterface;

  searchFilter: DataStoreFilter = {};

  constructor(params: DataStoreParams<S>) {
    super(params);

    extendObservable(this, {
      isUpdating: this.isUpdating,
      isUpdated: this.isUpdated,
      updateErrors: this.updateErrors,
      isCreating: this.isCreating,
      isCreated: this.isCreated,
      createFields: this.createFields,
      createErrors: this.createErrors,
      isRemoved: this.isRemoved,
      isRemoving: this.isRemoving,
      removeErrors: this.removeErrors,
      searchFilter: this.searchFilter,
      isSearching: this.isSearching,
      searchResults: this.searchResults,
      searchingError: this.searchingError,
    });

    reaction(
      () => this.searchFilter,
      () => {
        this.search();
      },
    );

    this.update = this.update.bind(this);
    this.remove = this.remove.bind(this);
    this.create = this.create.bind(this);
    this.search = this.search.bind(this);
    this.fetch = this.fetch.bind(this);
    this.applySearchFilter = this.applySearchFilter.bind(this);
    this.fetchDetails = this.fetchDetails.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
    this.clearUpdateError = this.clearUpdateError.bind(this);
    this.clearFlags = this.clearFlags.bind(this);
  }

  applySearchFilter(filter: DataStoreFilter): void {
    runInAction(() => {
      this.searchFilter = {
        ...this.searchFilter,
        ...filter,
        page: filter.page ? filter.page : 1,
        page_size: 10,
      };

      this.searchFilter = Object.keys(this.searchFilter)
        .filter((key) => this.searchFilter[key])
        .reduce(
          (a, k) => ({
            ...a,
            [k]: this.searchFilter[k],
          }),
          {} as DataStoreFilter,
        );
    });
  }

  async search(): Promise<void> {
    runInAction(() => {
      this.isSearching = true;
    });

    try {
      const result = await this.apiClient.fetch(this.searchFilter);

      const searchResults = result.data.map((el: any) => ({
        title: '',
        ...el,
      }));

      runInAction(() => {
        this.searchResults = [...searchResults];
        this.isSearching = false;
      });
    } catch (err: any) {
      runInAction(() => {
        this.searchingError = getErrorMessageWithCode(err);
        this.isSearching = false;
      });
    }
  }

  async remove(params: RStoreRemoveFields): Promise<void> {
    runInAction(() => {
      this.removeErrors = undefined;
      this.isRemoving = true;
      this.isRemoved = false;
    });

    try {
      await this.apiClient.remove(params);

      runInAction(() => {
        this.isRemoving = false;
        this.isRemoved = true;
      });
    } catch (err: any) {
      runInAction(() => {
        this.removeErrors = getErrorMessage(err);
        this.isRemoving = false;
      });
    }
  }

  async create(params: CStoreCreateFields): Promise<void> {
    runInAction(() => {
      this.createErrors = {};
      this.isCreating = true;
      this.isCreated = false;
      this.createFields = undefined;
    });

    try {
      this.createFields = this.prepareCreateParams(params);
      await this.apiClient.create(this.createFields);

      runInAction(() => {
        this.isCreating = false;
        this.isCreated = true;
        this.createFields = undefined;
      });
    } catch (err: any) {
      runInAction(() => {
        this.createErrors = getErrors(err);
        this.isCreating = false;
      });
    }
  }

  prepareCreateParams(params: CStoreCreateFields): CStoreCreateFields {
    return Object.keys(params)
      .filter((key) => {
        if (typeof params[key] === 'number') {
          return true;
        }

        if (typeof params[key] === 'boolean') {
          return true;
        }

        if (!params[key]) {
          return false;
        }

        return true;
      })
      .reduce(
        (a, k) => ({
          ...a,
          [k]: params[k],
        }),
        {},
      );
  }

  async update(params: UStoreUpdateFields): Promise<void> {
    runInAction(() => {
      if (this.updateErrors) {
        this.updateErrors = Object.keys(this.updateErrors)
          .filter((key) =>
            this.updateErrors ? !this.updateErrors[key] : false,
          )
          .reduce(
            (a, k) => ({
              ...a,
              [k]: this.updateErrors ? this.updateErrors[k] : false,
            }),
            {},
          );
      }

      this.isUpdating = {
        ...this.isUpdating,
        ...this.prepareUpdateObject(params, true),
      };

      this.isUpdated = false;
    });

    try {
      if (this.item) {
        const fields = this.prepareUpdateParams(params);

        await this.apiClient.update(fields);

        runInAction(() => {
          this.item = {
            ...this.item,
            ...fields,
          } as DataStoreItemInterface;

          this.isUpdating = {
            ...this.isUpdating,
            ...this.prepareUpdateObject(params, false),
          };

          this.isUpdated = true;
        });
      }
    } catch (err: any) {
      runInAction(() => {
        this.updateErrors = getErrors(err);
        this.isUpdating = {
          ...this.isUpdating,
          ...this.prepareUpdateObject(params, false),
        };
      });
    }
  }

  prepareUpdateParams(params: UStoreUpdateFields): UStoreUpdateFields {
    return Object.keys(params)
      .filter((key) => {
        if (typeof params[key] === 'number') {
          return true;
        }

        if (typeof params[key] === 'boolean') {
          return true;
        }

        if (typeof params[key] === 'string') {
          return true;
        }

        if (!params[key]) {
          return false;
        }

        return true;
      })
      .reduce(
        (a, k) => ({
          ...a,
          [k]: params[k],
        }),
        {},
      );
  }

  prepareUpdateObject(
    data: UStoreUpdateFields,
    isUpdating: boolean,
  ): UStoreObject {
    return Object.keys(data).reduce(
      (a, k) => ({
        ...a,
        [k]: isUpdating,
      }),
      {},
    );
  }

  clearFlags(): void {
    runInAction(() => {
      this.isRemoved = false;
      this.isCreated = false;
      this.isUpdated = false;
    });
  }

  clearErrors(): void {
    runInAction(() => {
      this.removeErrors = undefined;
      this.createErrors = undefined;
      this.updateErrors = undefined;
    });
  }

  clearFilters(): void {
    runInAction(() => {
      this.filter = {};
    });
  }

  clearUpdateError(name: string): void {
    runInAction(() => {
      if (this.updateErrors && this.updateErrors[name] && name) {
        delete this.updateErrors[name];
      }
      if (!name) {
        this.updateErrors = undefined;
      }
    });
  }
}

export default DataStore;
