import { ThemeObject } from 'reseller/interfaces';
import { THEMES } from '../constants';

const defaultTheme: ThemeObject = {
  name: THEMES.DEFAULT,
  rules: new Map<string, string>(),
};

defaultTheme.rules.set('--main-background', '#ffffff');
defaultTheme.rules.set('--semi-background', '#f9fafb');
defaultTheme.rules.set('--info-background', '#ffffff');
defaultTheme.rules.set('--main-shadow', 'rgba(0,0,0,.04)');
defaultTheme.rules.set('--inactive', 'rgba(0,0,0,.05)');
defaultTheme.rules.set('--filter-bg', 'rgba(0,0,0,.025)');
defaultTheme.rules.set('--main-dark', '#353f53');
defaultTheme.rules.set('--main-dark-01', 'rgba(53, 63,83, .1)');
defaultTheme.rules.set('--main-dark-border', 'rgba(34,36,38,.15)');
defaultTheme.rules.set('--hover-blue', '#03a9f4');
defaultTheme.rules.set('--menu-active', '#03a9f4');
defaultTheme.rules.set('--main-headers-text', 'rgba(0,0,0,.87)');
defaultTheme.rules.set('--main-text', '#6f6f6f');
defaultTheme.rules.set('--main-text-active', '#ffffff');
defaultTheme.rules.set('--dark-text-09', 'rgba(255,255,255,.9)');
defaultTheme.rules.set('--dark-text-03', 'rgba(255,255,255,.3)');
defaultTheme.rules.set('--dark-text-01', 'rgba(255,255,255,.1)');
defaultTheme.rules.set('--main-grad-1', '#7cc7ff');
defaultTheme.rules.set('--main-grad-2', '#1678c2');
defaultTheme.rules.set('--main-grad-3', '#2293e8');
defaultTheme.rules.set('--main-grad-4', '#14212b');

defaultTheme.rules.set('--inactive-text', 'rgba(0,0,0,.3)');
defaultTheme.rules.set('--error-text', '#db2828');
defaultTheme.rules.set('--text-02', 'rgba(111,111,111,.2)');
defaultTheme.rules.set('--remove-color', '#db2828');
defaultTheme.rules.set('--dnd-el', '#e0e1e2');
defaultTheme.rules.set('--dnd-el-active', '#c0c1c2');
defaultTheme.rules.set('--caption', 'rgba(255,255,255,.9)');
defaultTheme.rules.set('--dark-text-05', 'rgba(0,0,0,.5)');
defaultTheme.rules.set('--filter-under', '#f9fafb');

defaultTheme.rules.set('--grey-under', '#eaebed');

defaultTheme.rules.set('--status-in-use', '#d4d4d5');
defaultTheme.rules.set('--status-complete', '#21ba45');
defaultTheme.rules.set('--status-fail', '#db2828');
defaultTheme.rules.set('--status-free', '#00b5ad');

defaultTheme.rules.set('--button-save', '#21ba45');
defaultTheme.rules.set('--button-save-hover', 'rgba(33,186,69,.75)');
defaultTheme.rules.set('--button-transparent', 'transparent');

defaultTheme.rules.set('--main-color', '#ffffff');
defaultTheme.rules.set('--semi-color', '#353f53');

defaultTheme.rules.set('--inactive-row', 'rgba(251,189,8,.18)');

defaultTheme.rules.set('--rebill-success', '#adf3fa');
defaultTheme.rules.set('--rebill-success-hovered', '#85e4ea');
defaultTheme.rules.set('--order-success', '#cfffc9');
defaultTheme.rules.set('--order-success-hovered', '#8fe586');
defaultTheme.rules.set('--order-failed', '#fccece');
defaultTheme.rules.set('--order-failed-hovered', '#e89d9d');
defaultTheme.rules.set('--order-refund', '#fff4bf');
defaultTheme.rules.set('--order-refund-hovered', '#fae393');
defaultTheme.rules.set('--order-chargeback', '#e1d6fd');
defaultTheme.rules.set('--order-chargeback-hovered', '#c8b8f1');

defaultTheme.rules.set('--text-color', '#666666');
defaultTheme.rules.set('--highlighted-text', '#dcedfb');

defaultTheme.rules.set('--table-header-footer', 'rgba(0,0,50,.02)');

defaultTheme.rules.set('--good-statistic', '#4caf50');
defaultTheme.rules.set('--normal-statistic', '#ff9800');
defaultTheme.rules.set('--bad-statistic', '#f44336');

defaultTheme.rules.set('--table-border', '#e3e4e5');
defaultTheme.rules.set('--table-cell-hover', 'rgba(0,0,0,.02)');

defaultTheme.rules.set('--status-active', '#4caf50');
defaultTheme.rules.set('--status-inactive', '#a7a7a7');

defaultTheme.rules.set('--status-success-text', '#1a531b');
defaultTheme.rules.set('--status-success-bg', '#fcfff5');
defaultTheme.rules.set('--status-success-shadow', '#a3c293');

defaultTheme.rules.set('--status-error-text', '#912d2b');
defaultTheme.rules.set('--status-error-bg', '#fff6f6');
defaultTheme.rules.set('--status-error-shadow', '#e0b4b4');

defaultTheme.rules.set('--status-warning-text', '#794b02');
defaultTheme.rules.set('--status-warning-bg', '#fffaf3');
defaultTheme.rules.set('--status-warning-shadow', '#c9ba9b');

defaultTheme.rules.set('--status-info-text', '#0e566c');
defaultTheme.rules.set('--status-info-bg', '#f8ffff');
defaultTheme.rules.set('--status-info-shadow', '#a9d5de');

export default defaultTheme;
