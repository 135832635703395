import { LAST_LS_RESET_TIME } from './constants';

interface UseLocalStorage {
  setLocalStorageValue: (key: string, value: any) => void;
  getLocalStorageValue: (key: string) => string | null;
  removeLocalStorageValue: (key: string) => void;
  resetLocalStorageValues: (resetTime?: string) => void;
}

export const useLocalStorage = (): UseLocalStorage => {
  const getLocalStorageValue = (key: string): string | null =>
    localStorage.getItem(key);
  const setLocalStorageValue = (key: string, value: any): void => {
    localStorage.setItem(key, JSON.stringify(value));
  };
  const removeLocalStorageValue = (key: string): void => {
    localStorage.removeItem(key);
  };
  const resetLocalStorageValues = (resetTime?: string): void => {
    const lastTime = localStorage.getItem(LAST_LS_RESET_TIME);
    if (
      !resetTime ||
      (lastTime &&
        new Date(lastTime).getTime() === new Date(resetTime).getTime())
    ) {
      return;
    }

    const keys = Object.keys(localStorage);
    keys.forEach((el) => {
      if (el !== LAST_LS_RESET_TIME) {
        localStorage.removeItem(el);
      }
    });

    localStorage.setItem(LAST_LS_RESET_TIME, resetTime);
  };

  return {
    setLocalStorageValue,
    getLocalStorageValue,
    removeLocalStorageValue,
    resetLocalStorageValues,
  };
};

export default useLocalStorage;
