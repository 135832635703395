import {
  InvoiceFormInterface,
  InvoiceInterface,
  ApiCreateInvoiceParams,
} from 'shared/features/reseller-invoices/reseller-invoices.module';
import { extendObservable, reaction, runInAction } from 'mobx';
import { DataStore } from 'shared/features/data-store/data-store.module';
import { StoreInterface } from 'shared/store/interfaces';
import { resellerRoutesAsObject } from 'shared/routes/resellerRoutes';

class ResellerInvoicesStore extends DataStore {
  override item?: InvoiceInterface;

  override list: InvoiceInterface[] = [];

  localStorageName = 'ResellerInvoices';

  isInvoiceCreating = false;

  isInvoiceLoading = undefined as number | undefined;

  isInvoiceCreated = false;

  invoiceCreatingError = undefined as string | undefined;

  invoice?: InvoiceFormInterface;

  recentlyCreated: number[] = [];

  constructor(rootStore: StoreInterface) {
    super({
      rootStore,
      apiClient: rootStore.apiClient.resellerInvoices,
    });

    extendObservable(this, {
      invoice: this.invoice,
      isInvoiceCreating: this.isInvoiceCreating,
      isInvoiceCreated: this.isInvoiceCreated,
      isInvoiceLoading: this.isInvoiceLoading,
      invoiceCreatingError: this.invoiceCreatingError,
      recentlyCreated: this.recentlyCreated,
    });

    reaction(
      () => this.recentlyCreated,
      (createdInvoices) => {
        this.handleRecentlyCreatedChange(createdInvoices);
      },
    );

    this.createInvoice = this.createInvoice.bind(this);
    this.continueInvoice = this.continueInvoice.bind(this);
    this.resetInvoice = this.resetInvoice.bind(this);
    this.clearRecentlyCreated = this.clearRecentlyCreated.bind(this);
    this.handleRecentlyCreatedChange =
      this.handleRecentlyCreatedChange.bind(this);
    this.onInit = this.onInit.bind(this);

    this.onInit();
  }

  onInit(): void {
    const storedNotSeenValues: string[] = JSON.parse(
      localStorage.getItem(this.localStorageName) || '[]',
    );

    runInAction(() => {
      this.recentlyCreated = [...storedNotSeenValues.map((el) => Number(el))];
      this.handleRecentlyCreatedChange(this.recentlyCreated);
    });
  }

  handleRecentlyCreatedChange(changes: number[]): void {
    this.rootStore.notificationsStore.addRouteNotification(
      resellerRoutesAsObject.balance.routes.invoices.path,
      changes.length,
    );
  }

  async createInvoice(params: ApiCreateInvoiceParams): Promise<void> {
    runInAction(() => {
      this.isInvoiceCreating = true;
      this.isInvoiceCreated = false;
      this.invoiceCreatingError = undefined;
    });

    try {
      const invoice = await this.apiClient.createInvoice({
        ...params,
        amount: parseFloat(params.amount.toString()),
      });
      runInAction(() => {
        this.invoice = invoice;
        this.isInvoiceCreated = true;
        this.recentlyCreated = [...this.recentlyCreated, invoice.invoice_id];

        localStorage.setItem(
          this.localStorageName,
          JSON.stringify(this.recentlyCreated),
        );
      });
    } catch (err: any) {
      runInAction(() => {
        this.invoiceCreatingError = err.message;
      });
    } finally {
      runInAction(() => {
        this.isInvoiceCreating = false;
      });
    }
  }

  async continueInvoice(id: number): Promise<void> {
    runInAction(() => {
      this.isInvoiceLoading = id;
      this.invoiceCreatingError = undefined;
    });

    try {
      const invoice = await this.apiClient.continueInvoice(id);
      runInAction(() => {
        this.invoice = invoice;
      });
    } catch (err: any) {
      runInAction(() => {
        this.invoiceCreatingError = err.message;
      });
    } finally {
      runInAction(() => {
        this.isInvoiceLoading = undefined;
      });
    }
  }

  clearRecentlyCreated(): void {
    runInAction(() => {
      this.recentlyCreated = [];
      localStorage.setItem(
        this.localStorageName,
        JSON.stringify(this.recentlyCreated),
      );
    });
  }

  resetInvoice(): void {
    runInAction(() => {
      this.invoice = undefined;
    });
  }

  override clearErrors(): void {
    runInAction(() => {
      this.createErrors = undefined;
      this.updateErrors = undefined;
      this.searchingError = undefined;
      this.removeErrors = undefined;
      this.invoiceCreatingError = undefined;
    });
  }
}

export default ResellerInvoicesStore;
