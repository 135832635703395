import { ThemeObject } from 'reseller/interfaces';
import { runInAction, makeAutoObservable } from 'mobx';
import lightTheme from 'shared/lib/themes/lightTheme';
import darkTheme from 'shared/lib/themes/darkTheme';
import defaultTheme from 'shared/lib/themes/defaultTheme';

class ThemesStore {
  theme?: string;

  themes?: Array<ThemeObject>;

  constructor() {
    makeAutoObservable(this);
    this.theme = localStorage.getItem('theme') || 'default';
    this.themes = [defaultTheme, lightTheme, darkTheme];

    this.applyTheme(this.theme);
  }

  /**
   * Apply theme
   */
  applyTheme = async (theme: string): Promise<void> => {
    runInAction(() => {
      localStorage.setItem('theme', theme);

      const themeMap = this.themes?.find(
        (el: ThemeObject): ThemeObject | undefined | boolean => {
          if (el.name === theme) {
            return el;
          }

          return false;
        },
      );

      if (themeMap) {
        themeMap.rules.forEach((value: string, key: string) => {
          document.documentElement.style.setProperty(key, value);
        });
      }
    });
  };

  /**
   * Change theme
   */
  changeTheme = (theme: string): void => {
    runInAction(() => {
      this.theme = theme;
    });
  };
}

export default ThemesStore;
