import { AxiosInstance } from 'axios';
import {
  ResellerBestCountriesInterface,
  ResellerUnusedCodesInterface,
} from 'shared/features/statistic/statistic.store';

export interface BestCountriesResponse {
  data: ResellerBestCountriesInterface[];
}

export interface UnusedCodesResponse {
  data: ResellerUnusedCodesInterface;
}

class StatisticService {
  api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async fetchBestCountries(id: number): Promise<BestCountriesResponse> {
    const { data } = await this.api.get(
      `/resellers/${id}/statistics/best-countries`,
    );

    return data;
  }

  async fetchUnusedCodes(id: number): Promise<UnusedCodesResponse> {
    const { data } = await this.api.get(
      `/resellers/${id}/statistics/unused-codes`,
    );

    return data;
  }
}

export default StatisticService;
