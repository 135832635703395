import { NOTIFICATION_DELAY } from 'reseller/lib/service';
import { StoreInterface } from 'reseller/interfaces';
import { NotificationInterface } from 'reseller/interfaces/notifications';
import { makeAutoObservable, runInAction } from 'mobx';
import uniqid from 'uniqid';

class NotificationsStore implements StoreInterface {
  notifications: NotificationInterface[] = [];

  routeNotifications: {
    [key: string]: number,
  } = {
    support: 0,
  };

  constructor() {
    makeAutoObservable(this);

    this.addNotification = this.addNotification.bind(this);
    this.removeNotification = this.removeNotification.bind(this);
  }

  addNotification(item: NotificationInterface): void {
    runInAction(() => {
      const notificationId = uniqid();
      this.notifications.push({
        ...item,
        id: notificationId,
        timer: setTimeout(() => {
          this.removeNotification(notificationId);
        }, NOTIFICATION_DELAY[item.delay || 'normal']),
      });
    });
  }

  removeNotification(id: string): void {
    runInAction(() => {
      const removingNotification = this.notifications.find(
        (el) => el.id === id,
      );

      if (removingNotification && removingNotification.timer) {
        this.notifications = this.notifications.filter((el) => el.id !== id);
        clearTimeout(removingNotification.timer);
      }
    });
  }

  addRouteNotification(routePath: string, value: number): void {
    this.routeNotifications[routePath] = value;
  }
}

export default NotificationsStore;
