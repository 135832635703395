import { SimpleStoreInterface } from 'reseller/interfaces';
import { runInAction, makeAutoObservable } from 'mobx';
import { getErrorMessage } from 'shared/api';
import { CountryInterface } from '../dictionaries.module';

class CountriesStore implements SimpleStoreInterface<CountryInterface> {
  fetchMethod: () => any;

  isFetching = false;

  isReceived = false;

  fetchingError?: string;

  list = [] as CountryInterface[];

  constructor(fetchMethod: () => any) {
    makeAutoObservable(this);
    this.fetchMethod = fetchMethod;
  }

  async getList(): Promise<CountryInterface[]> {
    if (!this.isFetching && !this.isReceived) {
      await this.fetch();
    }

    return this.list || [];
  }

  async getListAsDropdown(valueName = 'id'): Promise<any[]> {
    const data = this.list;

    if (data && data.length) {
      return data.map((el) => ({
        key: `${el.code}_${el.id}`,
        value: el[valueName]?.toString(),
        text: el.name,
        code: el.code,
      }));
    }

    return [];
  }

  // eslint-disable-next-line consistent-return
  async fetch(): Promise<CountryInterface[] | void> {
    runInAction(() => {
      this.fetchingError = undefined;
      this.isFetching = true;
    });

    try {
      const { data } = await this.fetchMethod();

      const tempArr = [] as CountryInterface[];
      Object.keys(data).forEach((key: string): void => {
        tempArr.push(data[key]);
      });

      runInAction(() => {
        this.isFetching = false;
        this.isReceived = true;
        this.list = tempArr;
      });

      return this.list;
    } catch (err: any) {
      runInAction(() => {
        this.fetchingError = getErrorMessage(err);
        this.isFetching = false;
        this.isReceived = false;
      });
    }
  }
}

export default CountriesStore;
