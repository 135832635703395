import { extendObservable, runInAction } from 'mobx';
import { getErrorMessage } from 'shared/api';
import { MerchantInterface } from 'shared/interfaces/merchants';
import { PaymentSystemPivotTableI } from 'shared/interfaces/paymentSystems';
import {
  DataStore,
  ListReturnableStore,
  DataStoreItemInterface,
  UStoreUpdateFields,
  CStoreCreateFields,
} from 'shared/features/data-store/data-store.module';
import { StoreInterface } from 'shared/store/interfaces';

class MerchantsStore extends DataStore implements ListReturnableStore {
  isRefreshingToken = false;

  refreshTokenError?: string;

  override list: MerchantInterface[] = [];

  override item?: MerchantInterface;

  paymentSystemStore: PaymentSystemPivotTableI[] | undefined;

  fetchPSStoreError?: string;

  isFetchingPSStore?: boolean;

  constructor(rootStore: StoreInterface) {
    super({
      rootStore,
      apiClient: rootStore.apiClient.merchants,
    });

    extendObservable(this, {
      isRefreshingToken: this.isRefreshingToken,
      refreshTokenError: this.refreshTokenError,
      paymentSystemStore: this.paymentSystemStore,
      fetchPSStoreError: this.fetchPSStoreError,
      isFetchingPSStore: this.isFetchingPSStore,
    });

    this.refreshToken = this.refreshToken.bind(this);
    this.fetchPaymentSystemStore = this.fetchPaymentSystemStore.bind(this);
    this.getList = this.getList.bind(this);
    this.getListAsDropdown = this.getListAsDropdown.bind(this);
  }

  async getList(): Promise<DataStoreItemInterface[] | undefined> {
    if (this.list.length) {
      return this.list;
    }

    await this.fetch({
      page: 1,
      page_size: 999,
    });

    return this.list;
  }

  async getListAsDropdown(valueName = 'id'): Promise<any[] | []> {
    const data = await this.getList();

    if (data && data.length) {
      return (
        data.map((el) => ({
          key: el[valueName],
          value: el[valueName].toString(),
          text: el.name,
          name: el.name,
        })) || []
      );
    }

    return [];
  }

  /**
   * Refreshing token
   */
  async refreshToken(id: number): Promise<void> {
    runInAction(() => {
      this.refreshTokenError = undefined;
      this.isRefreshingToken = true;
    });

    try {
      const result = await this.apiClient.refreshMerchantToken(id);

      runInAction(() => {
        this.isRefreshingToken = false;
        if (this.item) {
          this.item.token = result.token;
        }
      });
    } catch (err: any) {
      runInAction(() => {
        this.refreshTokenError = getErrorMessage(err);
        this.isRefreshingToken = false;
      });
    }
  }

  /**
   * Get payment system pivot table
   */
  async fetchPaymentSystemStore(merchants: string[]): Promise<void> {
    runInAction(() => {
      this.fetchPSStoreError = undefined;
      this.isFetchingPSStore = true;
    });

    try {
      const result =
        await this.rootStore.apiClient.paymentSystems.fetchPivotTable({
          merchants,
        });

      runInAction(() => {
        if (result) {
          const resSorted = result
            .slice()
            .sort((a: any, b: any) => a.position - b.position);
          this.paymentSystemStore = resSorted;
        } else {
          this.paymentSystemStore = [];
        }

        this.isFetchingPSStore = false;
      });
    } catch (err: any) {
      runInAction(() => {
        this.fetchPSStoreError = getErrorMessage(err);
        this.isFetchingPSStore = false;
      });
    }
  }

  override prepareUpdateParams(params: UStoreUpdateFields): UStoreUpdateFields {
    return Object.keys(params)
      .filter((key) => {
        if (typeof params[key] === 'number') {
          return true;
        }

        if (typeof params[key] === 'boolean') {
          return true;
        }

        if (typeof params[key] === 'string') {
          return true;
        }

        if (!params[key]) {
          return false;
        }

        return true;
      })
      .reduce(
        (a, k) => ({
          ...a,
          [k]: params[k],
        }),
        {},
      );
  }

  /**
   * @override Prepare data before create
   */
  override prepareCreateParams(params: CStoreCreateFields): CStoreCreateFields {
    return Object.keys(params).reduce((a, k) => {
      switch (k) {
        case 'image':
          return {
            ...a,
            [k]: params[k].split(',')[1],
          };
        default: {
          if (params[k]) {
            return {
              ...a,
              [k]: params[k],
            };
          }
          return a;
        }
      }
    }, {});
  }
}

export default MerchantsStore;
