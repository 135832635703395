import { makeAutoObservable } from 'mobx';
import { StoreInterface } from 'shared/store/interfaces';
import SharedRootStore from 'shared/store/SharedRootStore';
import SharedApiClient from 'shared/api';
import BinTagsStore from './stores/BinTagsStore';
import ResellerBalanceHistoryTypesStore from './stores/ResellerBalanceHistoryTypesStore';
import BalanceHistoryTypesStore from './stores/BalanceHistoryTypesStore';
import ResellerInvoicesStatusesStore from './stores/ResellerInvoiceStatusesStore';
import ResellerTopupPaymentSystemsStore from './stores/ResellerTopupPaymentSystemsStore';
import CountriesStore from './stores/CountriesStore';

class DictionariesStore implements StoreInterface {
  rootStore: SharedRootStore;

  apiClient: SharedApiClient;

  countries: CountriesStore;

  binTags: BinTagsStore;

  resellerBalanceHistoryTypes: ResellerBalanceHistoryTypesStore;

  balanceHistoryTypes: BalanceHistoryTypesStore;

  resellerInvoicesStatuses: ResellerInvoicesStatusesStore;

  resellerTopupPaymentSystems: ResellerTopupPaymentSystemsStore;

  constructor(rootStore: SharedRootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.apiClient = rootStore.apiClient;

    this.countries = new CountriesStore(
      this.apiClient.dictionaries.fetchCountries,
    );

    this.binTags = new BinTagsStore(this.apiClient.dictionaries.fetchBinTags);

    this.resellerBalanceHistoryTypes = new ResellerBalanceHistoryTypesStore(
      this.apiClient.dictionaries.fetchResellerBalanceHistoryTypes,
    );

    this.balanceHistoryTypes = new BalanceHistoryTypesStore(
      this.apiClient.dictionaries.fetchBalanceHistoryTypes,
    );

    this.resellerInvoicesStatuses = new ResellerInvoicesStatusesStore(
      this.apiClient.dictionaries.fetchResellerInvoiceStatuses,
    );

    this.resellerTopupPaymentSystems = new ResellerTopupPaymentSystemsStore(
      this.apiClient.dictionaries.fetchPaymentSystems,
    );
  }

  async initCountries(): Promise<void> {
    this.countries.fetch();
  }
}

export default DictionariesStore;
