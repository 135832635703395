import { UserMenuItemInterface } from 'reseller/interfaces/userMenu';

export const adminRoutesAsObject = {
  resellerManagers: {
    id: 101,
    name: 'Reseller managers',
    title: 'Reseller managers',
    slug: 'reseller-managers',
    path: '/admin/reseller-managers',
    icon: 'user-circle-icon',
    status: 1,
    sort_order: 0,
    rsAdminRoute: true,
    description: 'Tune up Reseller Managers and their Resellers.',
    views: {
      details: {
        id: 1011,
        name: 'Reseller details',
        title: 'Reseller details',
        slug: 'reseller-details',
        description: 'Reseller details',
        path: '/admin/resellers/:id',
      },
      create: {
        id: 1012,
        name: 'Reseller managers create',
        title: 'Reseller managers create',
        slug: 'reseller-managers-create',
        description: 'Reseller managers create',
        path: '/admin/reseller-managers/:id/resellers/create',
      },
      edit: {
        id: 1012,
        name: 'Reseller managers edit',
        title: 'Reseller managers edit',
        slug: 'reseller-managers-edit',
        description: 'Reseller managers edit',
        path: '/admin/reseller-managers/:id',
      },
    },
  },
  codes: {
    id: 104,
    name: 'Codes',
    title: 'Codes',
    slug: 'codes',
    path: '/admin/codes',
    icon: 'barcode-icon',
    status: 1,
    sort_order: 0,
    rsAdminRoute: true,
    description: 'Section with list of codes and create/return functionality.',
    views: {
      details: {
        id: 1041,
        name: 'codeDetails',
        title: 'Code Details',
        slug: 'code-details',
        path: '/admin/codes/:id',
        description: 'Code details',
      },
      create: {
        id: 1042,
        name: 'codeCreate',
        title: 'Code Create',
        slug: 'code-create',
        path: '/admin/codes/create',
        description: 'Code create',
      },
    },
  },
  statistic: {
    id: 105,
    name: 'Statistic',
    title: 'Statistic',
    slug: 'statistic',
    path: '/admin/statistic',
    icon: 'charts-icon',
    status: 1,
    sort_order: 0,
    rsAdminRoute: true,
    description:
      'Section with small statistics about Resellers top countries and not activated codes.',
  },
};
const adminRoutes = Object.values(
  adminRoutesAsObject,
) as UserMenuItemInterface[];

export default adminRoutes;
