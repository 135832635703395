import { ExtendedErrorInterface } from 'shared/api/interfaces';
import { makeAutoObservable, runInAction } from 'mobx';
import { getErrorMessageWithCode } from 'shared/api';
import { StoreInterface } from '../../store/interfaces';
import BaseApiClient from '../../api';

export interface ResellerBestCountriesInterface {
  country_id: number;
  sales: number;
  clicks: number;
  ratio: string;
}

export interface ResellerCodeProductInterface {
  id: string;
  name: string;
  description: string;
}

export interface ResellerUnusedCodeInterface {
  product_id: string;
  count: number;
  product: ResellerCodeProductInterface;
}

export interface ResellerUnusedCodesInterface {
  items: ResellerUnusedCodeInterface[];
  total: number;
}

const sortCountries = (
  countryA: ResellerBestCountriesInterface,
  countryB: ResellerBestCountriesInterface,
) => {
  if (countryA.sales !== countryB.sales) {
    return countryB.sales - countryA.sales;
  }

  if (countryA.ratio !== countryB.ratio) {
    return Number(countryB.ratio) - Number(countryA.ratio);
  }

  if (countryA.sales !== countryB.sales) {
    return countryB.clicks - countryA.clicks;
  }

  return countryB.country_id - countryB.country_id;
};

class StatisticStore implements StoreInterface {
  apiClient: BaseApiClient;

  isFetching = false;

  fetchingError?: ExtendedErrorInterface;

  bestCountries: ResellerBestCountriesInterface[] = [];

  unusedCodes: ResellerUnusedCodesInterface = {
    items: [],
    total: 0,
  };

  constructor(readonly rootStore: StoreInterface) {
    makeAutoObservable(this);
    this.apiClient = rootStore.apiClient;

    this.clearData = this.clearData.bind(this);
    this.fetchUnusedCodes = this.fetchUnusedCodes.bind(this);
    this.fetchBestCountries = this.fetchBestCountries.bind(this);
  }

  async fetchBestCountries(id: number): Promise<void> {
    runInAction(() => {
      this.fetchingError = undefined;
      this.isFetching = true;
    });

    try {
      const result = await this.apiClient.statistic.fetchBestCountries(id);

      runInAction(() => {
        this.bestCountries = result.data.sort(sortCountries);
        this.isFetching = false;
      });
    } catch (err: any) {
      runInAction(() => {
        this.fetchingError = getErrorMessageWithCode(err);
        this.isFetching = false;
      });
    }
  }

  async fetchUnusedCodes(id: number): Promise<void> {
    runInAction(() => {
      this.fetchingError = undefined;
      this.isFetching = true;
    });

    try {
      const result = await this.apiClient.statistic.fetchUnusedCodes(id);

      runInAction(() => {
        this.unusedCodes = result.data;
        this.isFetching = false;
      });
    } catch (err: any) {
      runInAction(() => {
        this.fetchingError = getErrorMessageWithCode(err);
        this.isFetching = false;
      });
    }
  }

  clearData(): void {
    runInAction(() => {
      this.unusedCodes = {
        items: [],
        total: 0,
      };
      this.bestCountries = [];
    });
  }
}

export default StatisticStore;
