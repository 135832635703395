import { useEffect, ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import { useSharedStores } from 'shared/store';

const Auth = observer(({ children }: { children: React.ReactNode }) => {
  const { authStore } = useSharedStores();

  useEffect(() => {
    authStore.authenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children as ReactElement;
});

export default Auth;
