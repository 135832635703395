import { makeAutoObservable, runInAction } from 'mobx';

interface SupportTicketData {
  category?: string;
  subject?: string;
  content?: string;
  attachments?: any;
}

export type AppTheme = 'dark' | 'light';

class LayoutStore {
  mobileMenuOpened = false;

  topupBalanceOpened = false;

  supportTicketOpened = false;

  defaulSupportTicketData?: SupportTicketData;

  theme: AppTheme = 'light';

  constructor() {
    makeAutoObservable(this);

    this.toggleTopupBalance = this.toggleTopupBalance.bind(this);
    this.toggleSupportTicket = this.toggleSupportTicket.bind(this);
    this.setSupportTickerDefaultData =
      this.setSupportTickerDefaultData.bind(this);
    this.setTheme = this.setTheme.bind(this);
    this.initThemeValue = this.initThemeValue.bind(this);
    this.getThemeValueFromSystem = this.getThemeValueFromSystem.bind(this);
    this.setMobileMenuOpened = this.setMobileMenuOpened.bind(this);

    this.initThemeValue();
  }

  initThemeValue = (): void => {
    const storedTheme = localStorage.getItem('rs-theme') as AppTheme | null;

    if (storedTheme) {
      runInAction(() => {
        this.setTheme(storedTheme);
      });
    } else {
      this.getThemeValueFromSystem();
    }
  };

  setMobileMenuOpened = (opened: boolean): void => {
    runInAction(() => {
      this.mobileMenuOpened = opened;
    });
  };

  toggleTopupBalance = (opened: boolean): void => {
    runInAction(() => {
      this.topupBalanceOpened = opened;
    });
  };

  toggleSupportTicket = (opened: boolean): void => {
    if (!opened) {
      this.clearSupportTickerDefaulData();
    }
    runInAction(() => {
      this.supportTicketOpened = opened;
    });
  };

  setSupportTickerDefaultData = (data: SupportTicketData): void => {
    runInAction(() => {
      this.defaulSupportTicketData = data;
    });
  };

  clearSupportTickerDefaulData = (): void => {
    runInAction(() => {
      this.defaulSupportTicketData = undefined;
    });
  };

  getThemeValueFromSystem = (): void => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.setTheme('dark');
    } else {
      this.setTheme('light');
    }
  };

  setTheme = (theme: AppTheme): void => {
    runInAction(() => {
      this.theme = theme;
      localStorage.setItem('rs-theme', theme);
    });
  };
}

export default LayoutStore;
