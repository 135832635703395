import { UserMenuItemInterface } from 'reseller/interfaces/userMenu';

export const resellerRoutesAsObject = {
  addAnotherSite: {
    id: 0,
    name: 'newsite',
    title: 'Add another site',
    slug: 'another-site',
    path: '/reseller/add-another-site',
    status: 0,
    sort_order: 4,
    description: 'Add another site to reseller',
  },
  resellerCodes: {
    id: 1,
    status: 1,
    slug: '',
    path: '',
    sort_order: 2,
    name: 'Codes',
    title: 'Reseller codes',
    icon: 'barcode-icon',
    routes: {
      mycodes: {
        id: 11,
        name: 'My codes',
        title: 'My codes',
        slug: 'my-codes',
        path: '/reseller/my-codes',
        status: 1,
        sort_order: 0,
        description:
          'Section where you can view the status of your codes and return non-activated codes.',
      },
      buycodes: {
        id: 12,
        name: 'Buy codes',
        title: 'Buy codes',
        slug: 'buy-codes',
        path: '/reseller/buy-codes',
        status: 1,
        sort_order: 1,
        description:
          'Section where you can buy a code by choosing a store and tariff option.',
      },
    },
  },
  resellerSettings: {
    id: 2,
    status: 1,
    slug: '',
    path: '',
    sort_order: 3,
    name: 'Reseller settings',
    title: 'Reseller settings',
    icon: 'cog-icon',
    routes: {
      general: {
        id: 21,
        name: 'General settings',
        title: 'General',
        slug: 'general-settings',
        path: '/reseller/general-settings',
        description: 'General settings of selected reseller.',
      },
      stores: {
        id: 22,
        name: 'Store settings',
        title: 'Stores',
        slug: 'store-settings',
        path: '/reseller/store-settings',
        description: 'Store settings of selected reseller.',
      },
      countries: {
        id: 23,
        name: 'Countries settings',
        title: 'Countries',
        slug: 'countries-settings',
        path: '/reseller/countries-settings',
        description: 'List of enabled/disabled countries of selected reseller.',
      },
      paymentsystems: {
        id: 24,
        name: 'Payment systems settings',
        title: 'Payment systems',
        slug: 'payment-systems-settings',
        path: '/reseller/payment-systems-settings',
        description:
          'List of enabled/disabled payment systems of selected reseller.',
      },
    },
  },
  balance: {
    id: 3,
    status: 1,
    slug: '',
    path: '',
    sort_order: 3,
    name: 'Balance',
    title: 'Balance',
    icon: 'usd-icon',
    views: {
      details: {
        id: 25,
        name: 'Invoice details',
        title: 'Invoice details',
        slug: 'invoice-details',
        path: '/reseller/invoices/:id',
        status: 1,
        sort_order: 2,
        description: 'Invoice details',
      },
    },
    routes: {
      invoices: {
        id: 31,
        name: 'Invoices',
        title: 'Invoices',
        slug: 'invoices',
        path: '/reseller/invoices',
        status: 1,
        sort_order: 2,
        description: 'List of all invoices',
      },
      balanceHistory: {
        id: 32,
        name: 'History',
        title: 'History',
        slug: 'balance-history',
        path: '/reseller/balance-history',
        status: 1,
        sort_order: 2,
        description: 'Account balance history',
      },
    },
  },
  statistics: {
    id: 7,
    name: 'statistics',
    title: 'Statistics',
    slug: 'support',
    path: '/reseller/statistics',
    icon: 'charts-icon',
    status: 1,
    sort_order: 4,
    description:
      'Section with small statistics about top countries and not activated codes.',
  },
  support: {
    id: 8,
    name: 'support',
    title: 'Support',
    slug: 'support',
    path: '/reseller/support',
    icon: 'help-icon',
    status: 1,
    sort_order: 4,
    description: 'Easiest way to contact with support.',
    views: {
      supportTicket: {
        id: 8,
        name: 'supportTicket',
        title: 'Support Ticket',
        slug: 'support-ticket',
        path: '/reseller/support/:id',
        description: 'Support ticket details',
      },
    },
  },
};
const resellerRoutes = Object.values(
  resellerRoutesAsObject,
) as UserMenuItemInterface[];

export default resellerRoutes;
