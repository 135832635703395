import { runInAction } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { createContext, ReactNode, useContext, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

interface CreateStoreProps {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

interface ResponsiveContextProviderProps {
  children: ReactNode | undefined;
}

function createStore(props: CreateStoreProps) {
  return props;
}

type ptStore = ReturnType<typeof createStore>;

const ResponsiveContext = createContext<ptStore | null>(null);

export const ResponsiveContextProvider = observer(
  ({ children }: ResponsiveContextProviderProps) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isDesktop = useMediaQuery({ minWidth: 992 });

    const store = useLocalObservable(() => ({
      isMobile,
      isTablet,
      isDesktop,
      setLayout() {
        runInAction(() => {
          this.isMobile = useMediaQuery({ maxWidth: 767 });
          this.isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
          this.isDesktop = useMediaQuery({ minWidth: 992 });
        });
      },
    }));

    const handleResize = () => {
      // store.setLayout();
    };

    useEffect(() => {
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <ResponsiveContext.Provider value={store}>
        {children}
      </ResponsiveContext.Provider>
    );
  },
);

export const useResponsiveContext = (): any => {
  const store = useContext(ResponsiveContext);
  if (!store) {
    throw new Error(
      'ResponsiveContext must be used within a ResponsiveContextProvider.',
    );
  }
  return store;
};
