import { UserMenuItemInterface } from 'reseller/interfaces/userMenu';

export const registeredRoutesAsObject = {
  support: {
    id: 8,
    name: 'support',
    title: 'Support',
    slug: 'support',
    path: '/registered/support',
    icon: 'help-icon',
    status: 1,
    sort_order: 4,
    description: 'Easiest way to contact with support.',
    views: {
      supportTicket: {
        id: 8,
        name: 'supportTicket',
        title: 'Support Ticket',
        slug: 'support-ticket',
        path: '/registered/support/:id',
        description: 'Support ticket details',
      },
    },
  },
};
const registeredRoutes = Object.values(
  registeredRoutesAsObject,
) as UserMenuItemInterface[];

export default registeredRoutes;
