import { AxiosInstance } from 'axios';
import { IntersellApiClientInstance } from 'reseller/interfaces';
import {
  ApiFetchDetailsParams,
  ApiRemoveParams,
} from 'shared/api/interfaces';
import {
  MerchantInterface,
  MerchantsFilter,
  MerchantToken,
  MerchantCreateFields,
  MerchantUpdateFields,
} from 'shared/interfaces/merchants';

export interface AllMerchantsResponse {
  data: Array<MerchantInterface>;
  total: number;
}

class MerchantsService implements IntersellApiClientInstance {
  api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async fetch(params: MerchantsFilter): Promise<AllMerchantsResponse> {
    const { data } = await this.api.get('/merchants', { params });

    return data.data;
  }

  async fetchDetails(
    params: ApiFetchDetailsParams,
  ): Promise<MerchantInterface> {
    const { data } = await this.api.get(`/merchants/${params.id}`);

    return data.data;
  }

  async create(params: MerchantCreateFields): Promise<void> {
    const { data } = await this.api.post('/merchants', params);

    return data.data;
  }

  async update(params: MerchantUpdateFields): Promise<void> {
    const { data } = await this.api.patch(`/merchants/${params.id}`, params);

    return data.data;
  }

  async remove(params: ApiRemoveParams): Promise<void> {
    const { data } = await this.api.delete(`/merchants/${params.id}`);

    return data.data;
  }

  async refreshMerchantToken(id: number): Promise<MerchantToken> {
    const { data } = await this.api.post(`/merchants/${id}/refresh-token`);

    return data.data;
  }
}

export default MerchantsService;
