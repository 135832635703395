import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import uniqid from 'uniqid';
import { ReactElement } from 'react';

interface Props {
  children: ReactElement;
}

const GrowthBookContext = ({ children }: Props): JSX.Element => {
  let visitorId = localStorage.getItem('visitor_id');
  if (!visitorId) {
    visitorId = uniqid();
    localStorage.setItem('visitor_id', visitorId);
  }

  const growthbook = new GrowthBook({
    attributes: {
      id: visitorId,
    },
    trackingCallback: (experiment, result) => {
      // eslint-disable-next-line no-console
      console.log('Experiment tracking', {
        experimentId: experiment.key,
        variationId: result.variationId,
      });
    },
  });

  const featuresUrl = process.env.REACT_APP_FEATURES_ENDPOINT;
  const featuresKey = process.env.REACT_APP_FEATURES_KEY;

  const FEATURES_ENDPOINT = `${featuresUrl}/${featuresKey}`;

  if (featuresUrl && featuresKey) {
    fetch(FEATURES_ENDPOINT)
      .then((res) => res.json())
      .then((json) => {
        growthbook.setFeatures(json.features);
      });

    return (
      <GrowthBookProvider growthbook={growthbook}>
        {children}
      </GrowthBookProvider>
    );
  }
  return children;
};

export default GrowthBookContext;
