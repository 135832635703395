import ReactDOM from 'react-dom/client';
import { YMInitializer } from 'react-yandex-metrika';
import App from 'shared/components/App';
import GrowthBookContext from 'shared/lib/growthBookContext';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <>
    <YMInitializer
      accounts={[92794663]}
      options={{
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      }}
      version="2"
    />
    <GrowthBookContext>
      <App />
    </GrowthBookContext>
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
