import { jwtDecode, JwtPayload } from 'jwt-decode';

export interface AccessTokenDecoded {
  ownerId?: number;
  expiredAt?: number;
  issuedAt?: string;
  issuer?: number;
  jti?: string;
}

export default function decodeAccessToken(
  accessToken: string,
): AccessTokenDecoded | unknown {
  try {
    const accessTokenDecoded = jwtDecode<JwtPayload>(accessToken);
    if (!accessTokenDecoded || typeof accessTokenDecoded !== 'object') {
      return {};
    }

    const { exp } = accessTokenDecoded;
    if (!exp) {
      return {};
    }

    if (exp > Math.floor(Date.now() / 1000)) {
      // not expired
      return {
        ownerId: accessTokenDecoded.sub,
        expiredAt: accessTokenDecoded.exp,
        issuedAt: accessTokenDecoded.iat,
        issuer: accessTokenDecoded.iss,
        jti: accessTokenDecoded.jti,
      };
    }

    // expired
    return {};
  } catch (err) {
    return {};
  }
}
