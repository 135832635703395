import { AxiosInstance } from 'axios';
import {
  PaymentSystemsFilter,
  PaymentSystemsUpdateFields,
  PaymentSystemsCreateFields,
  PaymentSystemDetailsInterface,
  PaymentSystemPivotTableI,
} from 'shared/interfaces/paymentSystems';
import {
  AllPaymentSystemsResponse,
  FetchDetailsParams,
  FetchPivotTableParams,
} from './payment-systems.module';

class PaymentSystemsService {
  api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async fetch(
    params: PaymentSystemsFilter,
  ): Promise<AllPaymentSystemsResponse> {
    const { data } = await this.api.get('/payment-systems', { params });

    return data.data;
  }

  async create(params: PaymentSystemsCreateFields): Promise<void> {
    const { data } = await this.api.post('/payment-systems', params);

    return data.data;
  }

  async update(params: PaymentSystemsUpdateFields): Promise<void> {
    const { data } = await this.api.patch(
      `/payment-systems/${params.id}`,
      params,
    );

    return data.data;
  }

  async remove(id: number): Promise<void> {
    const { data } = await this.api.delete(`/payment-systems/${id}`);

    return data.data;
  }

  async fetchDetails(
    params: FetchDetailsParams,
  ): Promise<PaymentSystemDetailsInterface> {
    const { data } = await this.api.get(`/payment-systems/${params.id}`);

    return data.data;
  }

  async fetchPivotTable(
    params: FetchPivotTableParams,
  ): Promise<PaymentSystemPivotTableI[]> {
    const { data } = await this.api.get('/payment-systems/get-pivot-table', {
      params,
    });

    return data.data;
  }
}

export default PaymentSystemsService;
