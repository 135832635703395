import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import './scss/app.scss';
import { resellerRoutesAsObject } from 'shared/routes/resellerRoutes';
import { adminRoutesAsObject } from 'shared/routes/adminRoutes';
import { useSharedStores } from 'shared/store';
import { registeredRoutesAsObject } from 'shared/routes/registeredRoutes';
import loadable from '@loadable/component';
import Loading from '../Loading';

const BaseLayout = loadable(() => import('../BaseLayout'), {
  fallback: <Loading isLoading isAsyncComponentLoad />,
});
const RegisteredLayout = loadable(
  () => import('registered/components/RegistredLayout'),
  { fallback: <Loading isLoading isAsyncComponentLoad /> },
);
const ResellerLayout = loadable(
  () => import('reseller/components/ResellerLayout'),
  { fallback: <Loading isLoading isAsyncComponentLoad /> },
);
const AdminLayout = loadable(() => import('admin/components/AdminLayout'), {
  fallback: <Loading isLoading isAsyncComponentLoad />,
});
const AsyncHomePage = loadable(() => import('shared/pages/HomePage'), {
  fallback: <Loading isLoading isAsyncComponentLoad />,
});
const AsyncSharedPage = loadable(
  (props: any) => import(`shared/pages/${props.page}`),
  {
    cacheKey: (props) => props.page,
    fallback: <Loading isLoading isAsyncComponentLoad />,
  },
);
const AsyncResellerPage = loadable(
  (props: any) => import(`reseller/pages/${props.page}`),
  {
    cacheKey: (props) => props.page,
    fallback: <Loading isLoading isAsyncComponentLoad />,
  },
);
const AsyncAdminPage = loadable(
  (props: any) => import(`admin/pages/${props.page}`),
  {
    cacheKey: (props) => props.page,
    fallback: <Loading isLoading isAsyncComponentLoad />,
  },
);

const Routing = observer((): JSX.Element => {
  const { appUserStore } = useSharedStores();
  const location = useLocation();

  if (!appUserStore.user) {
    return (
      <Routes>
        <Route path="/login" element={<AsyncSharedPage page="LoginPage" />} />
        <Route
          path="/registration"
          element={<AsyncSharedPage page="Registration" />}
        />
        <Route
          path="*"
          element={
            <Navigate to="/login" state={{ referer: location.pathname }} />
          }
        />
      </Routes>
    );
  }

  if (appUserStore.isRegistered) {
    return (
      <BaseLayout>
        <RegisteredLayout>
          <Routes>
            <Route path="/" element={<AsyncSharedPage page="LoginPage" />} />
            <Route
              path="/login"
              element={<AsyncSharedPage page="LoginPage" />}
            />
            <Route
              path="/registration"
              element={<AsyncSharedPage page="Registration" />}
            />
            <Route
              path={registeredRoutesAsObject.support.path}
              element={<AsyncSharedPage page="Support" />}
            />
            <Route
              path={registeredRoutesAsObject.support.views.supportTicket.path}
              element={<AsyncSharedPage page="Support/TicketDetails" />}
            />
            <Route
              path="/registered"
              element={<AsyncHomePage routes={registeredRoutesAsObject} />}
            />
            <Route path="*" element={<AsyncSharedPage page="404Page" />} />
          </Routes>
        </RegisteredLayout>
      </BaseLayout>
    );
  }

  if (appUserStore.isReseller) {
    return (
      <BaseLayout>
        <ResellerLayout>
          <Routes>
            <Route path="/" element={<AsyncSharedPage page="LoginPage" />} />
            <Route
              path="/login"
              element={<AsyncSharedPage page="LoginPage" />}
            />
            <Route
              path="/registration"
              element={<AsyncSharedPage page="Registration" />}
            />
            <Route
              path={resellerRoutesAsObject.addAnotherSite.path}
              element={<AsyncResellerPage page="AddAnotherSite" />}
            />
            <Route
              path={resellerRoutesAsObject.resellerCodes.routes.mycodes.path}
              element={<AsyncResellerPage page="MyCodes" />}
            />
            <Route
              path={resellerRoutesAsObject.resellerCodes.routes.buycodes.path}
              element={<AsyncResellerPage page="BuyCodes" />}
            />
            <Route
              path={resellerRoutesAsObject.resellerSettings.routes.general.path}
              element={<AsyncResellerPage page="General" />}
            />
            <Route
              path={resellerRoutesAsObject.resellerSettings.routes.stores.path}
              element={<AsyncResellerPage page="Stores" />}
            />
            <Route
              path={
                resellerRoutesAsObject.resellerSettings.routes.countries.path
              }
              element={<AsyncResellerPage page="Countries" />}
            />
            <Route
              path={
                resellerRoutesAsObject.resellerSettings.routes.paymentsystems
                  .path
              }
              element={<AsyncResellerPage page="PaymentSystems" />}
            />

            <Route
              path={resellerRoutesAsObject.balance.routes.invoices.path}
              element={<AsyncResellerPage page="Invoices" />}
            />
            <Route
              path={resellerRoutesAsObject.balance.routes.balanceHistory.path}
              element={<AsyncResellerPage page="BalanceHistory" />}
            />
            <Route
              path={resellerRoutesAsObject.balance.views.details.path}
              element={<AsyncResellerPage page="InvoiceResult" />}
            />

            <Route
              path={resellerRoutesAsObject.statistics.path}
              element={<AsyncResellerPage page="Statistics" />}
            />

            <Route
              path={resellerRoutesAsObject.support.path}
              element={<AsyncSharedPage page="Support" />}
            />
            <Route
              path={resellerRoutesAsObject.support.views.supportTicket.path}
              element={<AsyncSharedPage page="Support/TicketDetails" />}
            />
            <Route
              path="/reseller"
              element={<AsyncHomePage routes={resellerRoutesAsObject} />}
            />
            <Route path="*" element={<AsyncSharedPage page="404Page" />} />
          </Routes>
        </ResellerLayout>
      </BaseLayout>
    );
  }

  return (
    <BaseLayout>
      <AdminLayout>
        <Routes>
          <Route path="/" element={<AsyncSharedPage page="LoginPage" />} />
          <Route path="/login" element={<AsyncSharedPage page="LoginPage" />} />
          <Route
            path="/registration"
            element={<AsyncSharedPage page="Registration" />}
          />
          <Route
            path="/admin"
            element={<AsyncHomePage routes={adminRoutesAsObject} />}
          />
          <Route
            path={adminRoutesAsObject.codes.path}
            element={<AsyncAdminPage page="Codes" />}
          />
          <Route
            path={adminRoutesAsObject.codes.views.create.path}
            element={<AsyncAdminPage page="Codes/CodeCreate" />}
          />
          <Route
            path={adminRoutesAsObject.resellerManagers.path}
            element={<AsyncAdminPage page="ResellerManagers" />}
          />
          <Route
            path={adminRoutesAsObject.resellerManagers.views.edit.path}
            element={
              <AsyncAdminPage page="ResellerManagers/RMDetails/ResellerManagerDetails" />
            }
          />
          <Route
            path={adminRoutesAsObject.resellerManagers.views.create.path}
            element={<AsyncAdminPage page="Resellers/ResellerCreate" />}
          />
          <Route
            path={adminRoutesAsObject.resellerManagers.views.details.path}
            element={<AsyncAdminPage page="Resellers" />}
          />
          <Route
            path={adminRoutesAsObject.statistic.path}
            element={<AsyncAdminPage page="AdminStatistics" />}
          />
          <Route path="*" element={<AsyncSharedPage page="404Page" />} />
        </Routes>
      </AdminLayout>
    </BaseLayout>
  );
});

export default Routing;
