import { SimpleStoreInterface } from 'reseller/interfaces';
import { runInAction, makeAutoObservable } from 'mobx';
import { getErrorMessage } from 'shared/api';
import { InvoiceStatusInterface } from '../dictionaries.module';

class ResellerInvoicesStatusesStore
  implements SimpleStoreInterface<InvoiceStatusInterface>
{
  fetchMethod: () => any;

  isFetching = false;

  isReceived = false;

  fetchingError?: string;

  list = [] as InvoiceStatusInterface[];

  constructor(fetchMethod: () => any) {
    makeAutoObservable(this);
    this.fetchMethod = fetchMethod;
  }

  async getList(): Promise<InvoiceStatusInterface[]> {
    if (!this.isFetching && !this.isReceived) {
      await this.fetch();
    }

    return this.list || [];
  }

  // eslint-disable-next-line consistent-return
  async fetch(): Promise<InvoiceStatusInterface[] | void> {
    runInAction(() => {
      this.fetchingError = undefined;
      this.isFetching = true;
      this.isReceived = false;
    });

    try {
      const { data } = await this.fetchMethod();

      const tempArr = [] as InvoiceStatusInterface[];
      Object.keys(data).forEach((key: string): void => {
        tempArr.push({
          id: parseInt(key, 10),
          name: data[key],
        });
      });

      runInAction(() => {
        this.isFetching = false;
        this.isReceived = true;
        this.list = tempArr;
      });

      return this.list;
    } catch (err: any) {
      runInAction(() => {
        this.fetchingError = getErrorMessage(err);
        this.isReceived = false;
        this.isFetching = false;
      });
    }
  }
}

export default ResellerInvoicesStatusesStore;
