import BaseApiClient, {
  getErrors,
  getErrorMessage,
  getErrorMessageWithCode,
} from 'shared/api/BaseApiClient';
import SupportService from 'shared/features/support/support.service';
import { AppUserService } from 'shared/features/app-user/app-user.module';
import { StatisticService } from 'shared/features/statistic/statistic.module';
import { DictionariesService } from 'shared/features/dictionaries/dictionaries.module';
import { ResellerProductsService } from 'shared/features/reseller-products/reseller-products.module';
import { PaymentSystemsService } from 'shared/features/payment-systems/payment-systems.module';
import { BillingsService } from 'shared/features/billings/billings.module';
import { MerchantsService } from 'shared/features/merchants/merchants.module';
import ResellerInvoicesService from 'shared/features/reseller-invoices/reseller-invoices.service';

class SharedApiClient extends BaseApiClient {
  readonly user: AppUserService;

  readonly support: SupportService;

  readonly statistic: StatisticService;

  readonly dictionaries: DictionariesService;

  readonly resellerProducts: ResellerProductsService;

  readonly paymentSystems: PaymentSystemsService;

  readonly billings: BillingsService;

  readonly merchants: MerchantsService;

  readonly resellerInvoices: ResellerInvoicesService;

  constructor() {
    super();
    this.user = new AppUserService(this.api);
    this.support = new SupportService(this.api);
    this.statistic = new StatisticService(this.api);
    this.dictionaries = new DictionariesService(this.api);
    this.resellerProducts = new ResellerProductsService(this.api);
    this.paymentSystems = new PaymentSystemsService(this.api);
    this.billings = new BillingsService(this.api);
    this.merchants = new MerchantsService(this.api);
    this.resellerInvoices = new ResellerInvoicesService(this.api);
  }
}

export { getErrors, getErrorMessage, getErrorMessageWithCode };
export default SharedApiClient;
