import { observer } from 'mobx-react-lite';
import { BrowserRouter } from 'react-router-dom';
import { CustomProvider } from 'rsuite';
import { useSharedStores } from 'shared/store';
import { Provider, ErrorBoundary } from '@rollbar/react';
import useLocalStorage from 'shared/lib/useLocalStorage';
import Auth from '../Auth';
import InAppErrorBoundary from '../InAppErrorBoundary';
import { ResponsiveContextProvider } from './responsiveContext';
import Routing from './Routing';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_ROLLBAR_ENV,
};

const App = (): JSX.Element => {
  const {
    layoutStore: { theme },
  } = useSharedStores();

  const { resetLocalStorageValues } = useLocalStorage();

  if (process.env.REACT_APP_RESET_LS && process.env.REACT_APP_BUILD_DATE) {
    resetLocalStorageValues(process.env.REACT_APP_BUILD_DATE);
  }

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary fallbackUI={InAppErrorBoundary}>
        <ResponsiveContextProvider>
          <CustomProvider theme={theme}>
            <BrowserRouter>
              <Auth>
                <Routing />
              </Auth>
            </BrowserRouter>
          </CustomProvider>
        </ResponsiveContextProvider>
      </ErrorBoundary>
    </Provider>
  );
};

export default observer(App);
