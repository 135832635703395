import { runInAction, makeAutoObservable } from 'mobx';
import { SimpleStoreInterface } from 'reseller/interfaces';
import { getErrorMessage } from 'shared/api';
import { ResellerTopupPaymentSystemsInterface } from '../dictionaries.module';

class ResellerTopupPaymentSystemsStore
  implements SimpleStoreInterface<ResellerTopupPaymentSystemsInterface>
{
  fetchMethod: () => any;

  isFetching = false;

  isReceived = false;

  fetchingError?: string;

  list = [] as ResellerTopupPaymentSystemsInterface[];

  constructor(fetchMethod: () => any) {
    makeAutoObservable(this);
    this.fetchMethod = fetchMethod;

    this.fetch = this.fetch.bind(this);
    this.getList = this.getList.bind(this);
  }

  async getList(): Promise<ResellerTopupPaymentSystemsInterface[]> {
    if (!this.isFetching && !this.isReceived) {
      await this.fetch();
    }

    return this.list || [];
  }

  // eslint-disable-next-line consistent-return
  async fetch(): Promise<ResellerTopupPaymentSystemsInterface[] | void> {
    runInAction(() => {
      this.fetchingError = undefined;
      this.isFetching = true;
      this.isReceived = false;
    });

    try {
      const result = await this.fetchMethod();

      runInAction(() => {
        this.isFetching = false;
        this.isReceived = true;
        this.list = result.map((el: ResellerTopupPaymentSystemsInterface) => ({
          id: el.name,
          ...el,
        }));
      });

      return this.list;
    } catch (err: any) {
      runInAction(() => {
        this.fetchingError = getErrorMessage(err);
        this.isReceived = false;
        this.isFetching = false;
      });
    }
  }
}

export default ResellerTopupPaymentSystemsStore;
