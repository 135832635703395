import { ErrorInterface } from 'shared/api/interfaces';
import {
  PaymentSystemInterface,
  PaymentSystemDetailsInterface,
} from 'shared/interfaces/paymentSystems';
import { runInAction } from 'mobx';
import { getErrors } from 'shared/api';
import {
  DataStore,
  ListReturnableStore,
  CStoreCreateFields,
  UStoreUpdateFields,
  DataStoreItemInterface,
} from 'shared/features/data-store/data-store.module';
import { StoreInterface } from 'shared/store/interfaces';
import { ResellerRootStore } from 'reseller/stores';
import { AdminRootStore } from 'admin/stores';

class PaymentSystemsStore extends DataStore implements ListReturnableStore {
  override list: PaymentSystemInterface[] = [];

  override item?: PaymentSystemDetailsInterface;

  constructor(rootStore: StoreInterface | ResellerRootStore | AdminRootStore) {
    super({
      rootStore,
      apiClient: rootStore.apiClient.paymentSystems,
    });

    this.getList = this.getList.bind(this);
    this.getListAsDropdown = this.getListAsDropdown.bind(this);
  }

  /**
   * @override Update payment system details
   */
  override async update(params: UStoreUpdateFields): Promise<void> {
    this.clearErrors();

    runInAction(() => {
      if (this.updateErrors) {
        this.updateErrors = Object.keys(this.updateErrors)
          .filter((key) => this.updateErrors && this.updateErrors[key])
          .reduce(
            (a, k) => ({
              ...a,
              [k]: this.updateErrors ? this.updateErrors[k] : '',
            }),
            {} as ErrorInterface,
          );
      }

      this.isUpdating = {
        ...this.isUpdating,
        ...this.prepareUpdateObject(params, true),
      };
    });

    try {
      if (this.item) {
        const fields = this.prepareUpdateParams(params);

        const updatedItem = await this.apiClient.update(fields);

        if (updatedItem) {
          runInAction(() => {
            this.item = updatedItem;
          });
        }

        runInAction(() => {
          this.isUpdating = {
            ...this.isUpdating,
            ...this.prepareUpdateObject(params, false),
          };

          this.isUpdated = !this.isUpdated;
        });
      }
    } catch (err: any) {
      runInAction(() => {
        this.updateErrors = getErrors(err);
        this.isUpdating = {
          ...this.isUpdating,
          ...this.prepareUpdateObject(params, false),
        };
      });
    }
  }

  async getList(): Promise<DataStoreItemInterface[] | undefined> {
    await this.fetch({
      page: 1,
      page_size: 999,
    });

    return this.list;
  }

  async getListAsDropdown(): Promise<any[] | []> {
    const data = await this.getList();

    if (data && data.length) {
      return (
        data.map((el) => ({
          key: el.id,
          value: el.id.toString(),
          text: el.name,
          id: el.id,
          name: el.name || el.short_name || '',
        })) || []
      );
    }

    return [];
  }

  /**
   * @override Prepare data before update
   */
  override prepareUpdateParams(params: UStoreUpdateFields): UStoreUpdateFields {
    return Object.keys(params).reduce((a, k) => {
      if (k === 'countries') {
        return {
          ...a,
          [k]: {
            enabled: params[k]?.enabled || null,
            disabled: params[k]?.disabled || null,
          },
        };
      }

      if (k === 'tariffs') {
        return {
          ...a,
          [k]: {
            min: parseInt(params[k]?.min, 10) || null,
            max: parseInt(params[k]?.max, 10) || null,
            fixed: params[k]?.fixed || null,
          },
        };
      }
      return {
        ...a,
        [k]: params[k],
      };
    }, {});
  }

  /**
   * @override Prepare data before create
   */
  override prepareCreateParams(params: CStoreCreateFields): CStoreCreateFields {
    return Object.keys(params).reduce((a, k) => {
      switch (k) {
        case 'countries':
          if (params[k] && (params[k].disabled || params[k].enabled)) {
            return {
              ...a,
              [k]: {
                enabled: params[k]?.enabled || null,
                disabled: params[k]?.disabled || null,
              },
            };
          }
          break;
        case 'tariffs':
          return {
            ...a,
            [k]: {
              min: parseInt(params[k]?.min, 10) || null,
              max: parseInt(params[k]?.max, 10) || null,
              fixed: params[k]?.fixed || null,
            },
          };
        case 'image':
          return {
            ...a,
            [k]: params[k].split(',')[1],
          };
        default: {
          if (params[k]) {
            return {
              ...a,
              [k]: params[k],
            };
          }
          return a;
        }
      }

      return a;
    }, {});
  }
}

export default PaymentSystemsStore;
