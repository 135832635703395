import { runInAction, reaction, makeAutoObservable } from 'mobx';
import BaseApiClient, { getErrors } from 'shared/api';
import { differenceInMinutes, fromUnixTime } from 'date-fns';
import { ErrorInterface } from 'shared/api/interfaces';
import { StoreInterface } from '../../store/interfaces';
import decodeAccessToken, { AccessTokenDecoded } from './decodeAccessToken';

class AuthStore implements StoreInterface {
  readonly apiClient: BaseApiClient;

  accessTokenDecoded: AccessTokenDecoded = {
    ownerId: undefined,
    expiredAt: undefined,
    issuedAt: undefined,
    issuer: undefined,
    jti: undefined,
  };

  accessTokenType?: string;

  loginError?: ErrorInterface;

  registrationError?: ErrorInterface;

  logoutError?: ErrorInterface;

  refreshingTokenError?: string;

  isLoginInProcess = false;

  isLogoutInProcess = false;

  isRegistrationInProcess = false;

  isUserAuthenticated = false;

  constructor(rootStore: StoreInterface) {
    makeAutoObservable(this);
    this.apiClient = rootStore.apiClient;

    // handle access token expiration time
    reaction(
      () => this.accessTokenDecoded.expiredAt,
      (expiredAt) => {
        if (expiredAt) {
          if (!differenceInMinutes(fromUnixTime(expiredAt), new Date())) {
            this.accessTokenDecoded = {};
            this.isUserAuthenticated = false;
            this.apiClient.auth.deleteAccessTokenFromCookie();
          }
        }
      },
    );

    // handle access token change
    reaction(
      () => this.apiClient.auth.accessToken,
      (token) => {
        if (!token) {
          this.apiClient.auth.deleteAccessTokenFromCookie();
          runInAction(() => {
            this.accessTokenDecoded = {};
            this.isUserAuthenticated = false;
          });
        } else {
          runInAction(() => {
            this.isUserAuthenticated = true;
          });
        }
      },
    );

    this.logout = this.logout.bind(this);
    this.registration = this.registration.bind(this);
    this.authenticate = this.authenticate.bind(this);
    this.loginByPassword = this.loginByPassword.bind(this);
    this.clearErrors = this.clearErrors.bind(this);
  }

  /**
   * Registration new user
   */
  async registration(props: {
    email: string;
    password: string;
    password_confirmation: string;
    privacy: boolean;
    captcha?: string | null;
  }): Promise<void> {
    runInAction(() => {
      this.isRegistrationInProcess = true;
      this.registrationError = undefined;
    });

    try {
      const { email, password, password_confirmation, privacy, captcha } =
        props;
      const { accessToken, tokenType } = await this.apiClient.auth.registerUser(
        {
          email,
          password,
          password_confirmation,
          privacy,
          captcha,
        },
      );

      runInAction(() => {
        this.accessTokenType = tokenType;
        this.accessTokenDecoded = decodeAccessToken(
          accessToken,
        ) as AccessTokenDecoded;
        this.isUserAuthenticated = true;
      });
    } catch (err: any) {
      runInAction(() => {
        this.registrationError = getErrors(err);
        this.isRegistrationInProcess = false;
      });
    } finally {
      runInAction(() => {
        this.isRegistrationInProcess = false;
      });
    }
  }

  /**
   * Authenticate user by cookies
   */
  async authenticate(): Promise<void> {
    runInAction(() => {
      this.isLoginInProcess = true;
      this.loginError = undefined;
    });

    try {
      const accessTokenInfo =
        await this.apiClient.auth.getAccessTokenFromCookie();

      if (accessTokenInfo) {
        const { accessToken, tokenType } = accessTokenInfo;
        runInAction(() => {
          this.accessTokenType = tokenType;
          this.accessTokenDecoded = decodeAccessToken(
            accessToken,
          ) as AccessTokenDecoded;
          this.isUserAuthenticated = true;
        });
      }
    } catch (err: any) {
      runInAction(() => {
        this.loginError = getErrors(err);
        this.isUserAuthenticated = false;
      });
    } finally {
      runInAction(() => {
        this.isLoginInProcess = false;
      });
    }
  }

  resetToken(): void {
    this.accessTokenDecoded = {
      ownerId: undefined,
      expiredAt: undefined,
      issuedAt: undefined,
      issuer: undefined,
      jti: undefined,
    };

    this.accessTokenType = undefined;
  }

  /**
   * Login user
   */
  async loginByPassword(props: {
    email: string;
    password: string;
    captcha?: string | null;
  }): Promise<void> {
    const { email, password, captcha } = props;

    this.isLoginInProcess = true;
    this.isUserAuthenticated = false;
    this.loginError = undefined;
    this.resetToken();

    try {
      const { accessToken, tokenType } =
        await this.apiClient.auth.getAccessTokenByPassword({
          email,
          password,
          captcha,
        });

      runInAction(() => {
        this.accessTokenType = tokenType;
        this.accessTokenDecoded = decodeAccessToken(
          accessToken,
        ) as AccessTokenDecoded;
        this.isUserAuthenticated = true;
      });
    } catch (err: any) {
      runInAction(() => {
        this.isUserAuthenticated = false;
        this.loginError = getErrors(err);
        if (err.response?.status === 401) {
          this.loginError = {
            server: 'Incorrect login or password. Please try again!',
          };
        }
      });
    } finally {
      runInAction(() => {
        this.isLoginInProcess = false;
      });
    }
  }

  /**
   * Logout user
   */
  async logout(): Promise<void> {
    runInAction(() => {
      this.isLogoutInProcess = true;
      this.logoutError = undefined;
    });
    try {
      await this.apiClient.auth.deleteAccessToken();
      this.isUserAuthenticated = false;
    } catch (err: any) {
      runInAction(() => {
        this.logoutError = getErrors(err);
      });
    } finally {
      runInAction(() => {
        this.isLogoutInProcess = false;
      });
    }
  }

  setLoginError(message: string): void {
    this.loginError = { login: message };
  }

  clearErrors(): void {
    this.loginError = undefined;
    this.registrationError = undefined;
  }
}

export default AuthStore;
