export const THEMES = {
  DARK: 'dark',
  LIGHT: 'light',
  DEFAULT: 'default',
};

export const CELL_STYLES = {
  ORDER_SUCCESS: 'statusSuccess1',
  REBILL_SUCCESS: 'statusSuccess2',
  FAILED: 'statusFailed',
  REFUND: 'statusRefund',
  CHARGEBACK: 'statusChargeback',
};

export const ROLES = {
  SUPER_ADMIN: 'superAdmin',
  RESELLER_ROLE: 'Reseller',
  REGISTERED_ROLE: 'registered',
};

export const allowedRoles = {
  [ROLES.SUPER_ADMIN]: 1,
  [ROLES.RESELLER_ROLE]: 1,
  [ROLES.REGISTERED_ROLE]: 1,
};

export const FILTER_TYPE_INPUT = 0;
export const FILTER_TYPE_SWITCH = 1;
export const FILTER_TYPE_DROPDOWN = 2;
export const FILTER_TYPE_DATESRANGE = 3;
export const FILTER_TYPE_DATE = 4;

export const DEFAULT_STATUSES = {
  ON: {
    name: 'Yes',
    value: '1',
  },
  OFF: {
    name: 'No',
    value: '0',
  },
};

export const DEFAULT_STATUSES_LIST = [
  {
    name: DEFAULT_STATUSES.ON.name,
    value: DEFAULT_STATUSES.ON.value,
  },
  {
    name: DEFAULT_STATUSES.OFF.name,
    value: DEFAULT_STATUSES.OFF.value,
  },
];

export const ACTIVATION_STATUSES_LIST = [
  {
    name: DEFAULT_STATUSES.ON.name,
    value: DEFAULT_STATUSES.ON.value,
  },
  {
    name: DEFAULT_STATUSES.OFF.name,
    value: DEFAULT_STATUSES.OFF.value,
  },
];

export const DISABLED_TABLE_COLS = ['actions', 'check'];

export const AVAILABLE_STORES = ['k2s', 'tz', 'fb', 'pp'];

export const MP_STORES = [...AVAILABLE_STORES];

export const SUPPORT_STATUSES = ['open', 'waiting', 'closed'];

export const SUPPORT_CATEGORY_OPTIONS = [
  'Questions about platform tools',
  'VPF subscription issues',
  'Questions about API',
  'Sales statistics questions',
  'Payouts questions',
  'Feature request',
  'Other',
];

export const LAST_LS_RESET_TIME = 'last_ls_reset_time';
