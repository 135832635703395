import { makeObservable, observable, reaction, runInAction } from 'mobx';
import { getErrorMessageWithCode } from 'shared/api';
import { ApiInstance, ErrorInterface } from 'shared/api/interfaces';
import {
  DataStoreFetchDetailsParams,
  DataStoreFilter,
  DataStoreItemInterface,
} from 'shared/features/data-store/data-store.module';
import { AbstractStoreInstance } from '../../store/interfaces';

export interface IDataStoreParams<S> {
  rootStore: S;
  apiClient: ApiInstance;
}

abstract class ReadOnlyDataStore<S = AbstractStoreInstance> {
  rootStore: S;

  apiClient: ApiInstance;

  isFetching = false;

  fetchingError?: ErrorInterface;

  isDetailsFetching = false;

  fetchingDetailsError?: ErrorInterface;

  hasNextPage = false;

  isReceived = false;

  item?: DataStoreItemInterface = undefined;

  list: DataStoreItemInterface[] = [];

  filter: DataStoreFilter = {};

  constructor(params: IDataStoreParams<S>) {
    this.rootStore = params.rootStore;
    this.apiClient = params.apiClient;

    makeObservable(this, {
      isFetching: observable,
      isReceived: observable,
      isDetailsFetching: observable,
      fetchingError: observable,
      fetchingDetailsError: observable,
      hasNextPage: observable,
      list: observable,
      item: observable,
      filter: observable,
    });

    reaction(
      () => this.filter,
      () => {
        this.fetch();
      },
    );

    this.fetch = this.fetch.bind(this);
    this.afterFetchHook = this.afterFetchHook.bind(this);
    this.fetchDetails = this.fetchDetails.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.prepareFilter = this.prepareFilter.bind(this);
    this.clearDetails = this.clearDetails.bind(this);
    this.resetList = this.resetList.bind(this);
  }

  afterFetchHook(
    data: DataStoreItemInterface[] = [],
  ): DataStoreItemInterface[] {
    return data;
  }

  async fetch(
    customFilter?: DataStoreFilter,
  ): Promise<DataStoreItemInterface[] | void> {
    runInAction(() => {
      this.fetchingError = undefined;
      this.isFetching = true;
      this.isReceived = false;
    });

    try {
      const filter = this.prepareFilter(customFilter || this.filter);
      const result = await this.apiClient.fetch(filter);

      runInAction(() => {
        this.list = this.afterFetchHook([...result.data]);
        this.hasNextPage = !!result.next_page_url;
        this.isFetching = false;
        this.isReceived = true;
      });
    } catch (err: any) {
      runInAction(() => {
        this.fetchingError = getErrorMessageWithCode(err);
        this.isFetching = false;
      });
    }

    return this.list;
  }

  prepareFilter(filter: DataStoreFilter): DataStoreFilter {
    return Object.keys(filter)
      .filter((key) => filter[key])
      .reduce((a, k) => {
        if (k && k.includes('_from_to')) {
          const kTemp = k.split('_from_to')[0];
          const val = filter[k]?.toString().split(' - ') || [];

          return {
            ...a,
            [`${kTemp}_from`]: val[0],
            [`${kTemp}_to`]: val[1],
          };
        }

        return {
          ...a,
          [k]: filter[k],
        };
      }, {} as DataStoreFilter);
  }

  async fetchDetails(params: DataStoreFetchDetailsParams): Promise<void> {
    runInAction(() => {
      this.fetchingDetailsError = undefined;
      this.isDetailsFetching = true;
    });

    try {
      const result = await this.apiClient.fetchDetails(params);

      runInAction(() => {
        this.item = result;
      });
    } catch (err: any) {
      runInAction(() => {
        this.fetchingDetailsError = getErrorMessageWithCode(err);
      });
    } finally {
      runInAction(() => {
        this.isDetailsFetching = false;
      });
    }
  }

  clearDetails(): void {
    runInAction(() => {
      this.item = undefined;
    });
  }

  resetList(): void {
    runInAction(() => {
      this.list = [];
    });
  }

  applyFilter(filter?: DataStoreFilter): void {
    runInAction(() => {
      this.filter = {
        ...this.filter,
        ...filter,
        page: filter?.page ? filter.page : 1,
        page_size: filter?.page_size ? filter.page_size : this.filter.page_size,
      };

      this.filter = Object.keys(this.filter)
        .filter((key) => this.filter[key])
        .reduce(
          (a, k) => ({
            ...a,
            [k]: this.filter[k],
          }),
          {} as DataStoreFilter,
        );
    });
  }

  resetFilter(defaultFilter?: DataStoreFilter): void {
    runInAction(() => {
      this.filter = {
        page: this.filter.page,
        page_size: this.filter.page_size,
        ...defaultFilter,
      };
    });
  }
}

export default ReadOnlyDataStore;
