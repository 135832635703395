import { ErrorInterface } from 'shared/api/interfaces';
import {
  BillingDetailsInterface,
  BillingInterface,
} from 'shared/interfaces/billings';
import { runInAction } from 'mobx';
import { getErrors } from 'shared/api';
import {
  BinTagInterface,
  CountryInterface,
} from 'shared/features/dictionaries/dictionaries.module';
import {
  DataStore,
  ListReturnableStore,
  DataStoreItemInterface,
  CStoreCreateFields,
  UStoreUpdateFields,
} from 'shared/features/data-store/data-store.module';
import { PaymentSystemsStore } from 'shared/features/payment-systems/payment-systems.module';
import { MerchantsStore } from 'shared/features/merchants/merchants.module';
import { StoreInterface } from 'shared/store/interfaces';

class BillingsStore extends DataStore implements ListReturnableStore {
  paymentSystems: PaymentSystemsStore;

  merchantsStore: MerchantsStore;

  override list: BillingInterface[] = [];

  override item?: BillingDetailsInterface;

  constructor(rootStore: StoreInterface) {
    super({
      rootStore,
      apiClient: rootStore.apiClient.billings,
    });

    this.paymentSystems = this.rootStore.paymentSystemsStore;
    this.merchantsStore = this.rootStore.merchantsStore;

    this.getList = this.getList.bind(this);
    this.getListAsDropdown = this.getListAsDropdown.bind(this);
  }

  async getList(): Promise<DataStoreItemInterface[] | undefined> {
    await this.fetch({
      page: 1,
      page_size: 999,
    });

    return this.list;
  }

  async getListAsDropdown(): Promise<any[] | []> {
    const data = await this.getList();

    if (data && data.length) {
      return (
        data.map((el) => ({
          key: el.id,
          value: el.id.toString(),
          text: el.name,
          id: el.id,
          name: el.name || el.short_name || '',
        })) || []
      );
    }

    return [];
  }

  /**
   * @override Update billing details
   */
  override update = async (params: UStoreUpdateFields): Promise<void> => {
    runInAction(() => {
      if (this.updateErrors) {
        this.updateErrors = Object.keys(this.updateErrors)
          .filter((key) => this.updateErrors && this.updateErrors[key])
          .reduce(
            (a, k) => ({
              ...a,
              [k]: this.updateErrors ? this.updateErrors[k] : '',
            }),
            {} as ErrorInterface,
          );
      }

      this.isUpdating = {
        ...this.isUpdating,
        ...this.prepareUpdateObject(params, true),
      };
    });

    try {
      const { id, psToAdd, psToRemove, payments } = params;

      if (this.item) {
        if (psToAdd || psToRemove) {
          // Revoke payment systems
          if (psToRemove.length) {
            await this.apiClient.removePaymentSystems({
              id,
              payments: psToRemove,
            });
          }

          // Add payment systems
          if (psToAdd.length) {
            await this.apiClient.addPaymentSystems({
              id,
              payments: psToAdd,
            });
          }

          runInAction(() => {
            this.item = {
              ...this.item,
              payments,
            } as BillingDetailsInterface;
          });
        } else {
          const fields = this.prepareUpdateParams(params);

          const updatedItem = await this.apiClient.update(fields);

          if (updatedItem) {
            runInAction(() => {
              this.item = updatedItem;
            });
          }
        }

        runInAction(() => {
          this.isUpdating = {
            ...this.isUpdating,
            ...this.prepareUpdateObject(params, false),
          };
          this.isUpdated = !this.isUpdated;
        });
      }
    } catch (err: any) {
      runInAction(() => {
        this.updateErrors = getErrors(err);
        this.isUpdating = {
          ...this.isUpdating,
          ...this.prepareUpdateObject(params, false),
        };
      });
    }
  };

  /**
   * @override Prepare data before update
   */
  override prepareUpdateParams(params: UStoreUpdateFields): UStoreUpdateFields {
    return Object.keys(params).reduce((a, k) => {
      if (k === 'display_for_countries') {
        return {
          ...a,
          [k]:
            params[k] && params[k].length
              ? params[k].map((el: CountryInterface) => el.code)
              : null,
        };
      }

      if (k === 'countries') {
        return {
          ...a,
          [k]: {
            enabled: (params[k]?.enabled && params[k].enabled.length > 0) ? params[k].enabled : null,
            disabled: (params[k]?.disabled && params[k].disabled.length > 0) ? params[k].disabled : null,
          },
        };
      }

      if (k === 'bin_tags') {
        return {
          ...a,
          [k]: {
            enabled: (params[k]?.enabled && params[k].enabled.length > 0) ? params[k].enabled : null,
            disabled: (params[k]?.disabled && params[k].disabled.length > 0) ? params[k].disabled : null,
          },
        };
      }

      if (k === 'tariffs') {
        return {
          ...a,
          [k]: {
            min: parseInt(params[k]?.min, 10) || null,
            max: parseInt(params[k]?.max, 10) || null,
            fixed: params[k]?.fixed || null,
            upgrade: params[k]?.upgrade || false,
          },
        };
      }

      return {
        ...a,
        [k]: params[k],
      };
    }, {});
  }

  /**
   * @override Prepare data before create
   */
  override prepareCreateParams(params: CStoreCreateFields): CStoreCreateFields {
    return Object.keys(params).reduce((a, k) => {
      if (k === 'display_for_countries' && params[k]) {
        return {
          ...a,
          [k]:
            params[k] && params[k].length
              ? params[k].map((el: CountryInterface) => el.code)
              : null,
        };
      }

      if (
        k === 'countries' &&
        params[k] &&
        (params[k].disabled || params[k].enabled)
      ) {
        return {
          ...a,
          [k]: {
            enabled:
              params[k].enabled && params[k].enabled.length ?
                params[k].enabled.map((el: CountryInterface) => el) : null,
            disabled:
              params[k].disabled && params[k].disabled.length ?
                params[k].disabled.map((el: CountryInterface) => el) : null,
          },
        };
      }

      if (
        k === 'bin_tags' &&
        params[k] &&
        (params[k].enabled || params[k].disabled)
      ) {
        return {
          ...a,
          [k]: {
            enabled:
              params[k]?.enabled?.map((el: BinTagInterface) => el.id) || null,
            disabled:
              params[k]?.disabled?.map((el: BinTagInterface) => el.id) || null,
          },
        };
      }

      if (
        k === 'tariffs' &&
        params[k] &&
        ((params[k].min && params[k].max) || params[k].fixed)
      ) {
        return {
          ...a,
          [k]: {
            min: parseInt(params[k]?.min, 10) || null,
            max: parseInt(params[k]?.max, 10) || null,
            fixed: params[k]?.fixed || null,
          },
        };
      }

      if (params[k]) {
        return {
          ...a,
          [k]: params[k],
        };
      }

      return a;
    }, {});
  }
}

export default BillingsStore;
