import { AxiosInstance } from 'axios';
import {
  ApiFetchDetailsParams,
  ApiRemoveParams,
} from 'shared/api/interfaces';
import {
  BillingDetailsInterface,
  BillingInterface,
  BillingsFilter,
  BillingUpdateFields,
} from 'shared/interfaces/billings';
import { PaymentSystemsUpdateFields } from 'shared/interfaces/paymentSystems';
import { AllBillingsResponse, PatchBillingResponse } from './billings.module';

class BillingsService {
  api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async fetch(params: BillingsFilter): Promise<AllBillingsResponse> {
    const { data } = await this.api.get('/billings', { params });

    return data.data;
  }

  async update(params: BillingUpdateFields): Promise<BillingInterface> {
    const { data }: PatchBillingResponse = await this.api.patch(
      `/billings/${params.id}`,
      params,
    );

    return data.data;
  }

  async remove(params: ApiRemoveParams): Promise<void> {
    const { data } = await this.api.delete(`/billings/${params.id}`);

    return data.data;
  }

  async fetchDetails(
    params: ApiFetchDetailsParams,
  ): Promise<BillingDetailsInterface> {
    const { data } = await this.api.get(`/billings/${params.id}`);

    return data.data;
  }

  async addPaymentSystems(params: PaymentSystemsUpdateFields): Promise<void> {
    const { data } = await this.api.post(
      `/billings/${params.id}/add-payments`,
      params,
    );

    return data.data;
  }

  async removePaymentSystems(
    params: PaymentSystemsUpdateFields,
  ): Promise<void> {
    const { data } = await this.api.post(
      `/billings/${params.id}/revoke-payments`,
      params,
    );

    return data.data;
  }
}

export default BillingsService;
