import { AxiosInstance } from 'axios';
import { DataStoreFilter } from 'shared/features/data-store/data-store.module';
import { AllProductsResponse } from './reseller-products.module';


class ResellerProductsService {
  api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async fetch(params: DataStoreFilter): Promise<AllProductsResponse> {
    const { data } = await this.api.get('/products', { params });

    return data.data;
  }
}

export default ResellerProductsService;
