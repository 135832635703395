import { AxiosInstance } from 'axios';
import { CountryInterface, BinTagInterface } from './dictionaries.module';

interface DictionaryResponse<T = string> {
  data: {
    [key: string]: T;
  };
}

class Dictionaries {
  api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  fetchCountries = async (): Promise<DictionaryResponse<CountryInterface>> => {
    const { data } = await this.api.get('/dictionaries/countries');

    return data;
  };

  fetchBinTags = async (): Promise<DictionaryResponse<BinTagInterface>> => {
    const { data } = await this.api.get('/dictionaries/bin-tags');

    return data;
  };

  fetchBalanceHistoryTypes = async (): Promise<DictionaryResponse<string>> => {
    const { data } = await this.api.get('/dictionaries/balance-history-types');

    return data;
  };

  fetchResellerBalanceHistoryTypes = async (): Promise<DictionaryResponse> => {
    const { data } = await this.api.get('/dictionaries/balance-history-types');

    return data;
  };

  fetchResellerInvoiceStatuses = async (): Promise<DictionaryResponse> => {
    const { data } = await this.api.get('/dictionaries/invoice-statuses');

    return data;
  };

  fetchPaymentSystems = async (): Promise<DictionaryResponse> => {
    const { data } = await this.api.get('/resellers-payment-systems');

    return data.data;
  };
}

export default Dictionaries;
