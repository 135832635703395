import { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';
import { makeAutoObservable } from 'mobx';
import fromUnixTime from 'date-fns/fromUnixTime';

interface GetAccessTokenParams {
  email: string;
  password: string;
  captcha?: string | null;
}

interface RegisterParams {
  email: string;
  password: string;
  password_confirmation: string;
  privacy: boolean;
  captcha?: string | null;
}

interface GetAccessTokenResponse {
  accessToken: string;
  tokenType: string;
  expires: number;
}

class Auth {
  api: AxiosInstance;

  accessToken?: string;

  tokenType?: string;

  expires?: number;

  constructor(api: AxiosInstance) {
    makeAutoObservable(this);
    // eslint-disable-next-line prefer-object-spread
    this.api = Object.assign({}, api);
  }

  async getAccessTokenFromCookie(): Promise<
    GetAccessTokenResponse | undefined
  > {
    const accessToken = Cookies.get('accessToken');
    const tokenType = Cookies.get('tokenType');
    const expires = Number(Cookies.get('expires'));

    if (accessToken && tokenType) {
      this.accessToken = accessToken;
      this.tokenType = tokenType;
      this.expires = expires;

      return {
        accessToken,
        tokenType,
        expires,
      };
    }

    return undefined;
  }

  async registerUser(params: RegisterParams): Promise<GetAccessTokenResponse> {
    const { email, password, password_confirmation, privacy, captcha } = params;
    const response = await this.api.post('/auth/register', {
      email,
      password,
      password_confirmation,
      privacy,
      captcha,
    });

    const {
      token: accessToken,
      token_type: tokenType,
      expires,
    } = response.data.data;

    if (accessToken && tokenType) {
      this.addAccessTokenToCookie(accessToken, tokenType, expires);
    }

    return {
      accessToken,
      tokenType,
      expires,
    };
  }

  async deleteAccessToken(): Promise<void> {
    const response = await this.api.post('/auth/logout');

    if (response.status === 200) {
      this.deleteAccessTokenFromCookie();
    }
  }

  async getAccessTokenByPassword(
    params: GetAccessTokenParams,
  ): Promise<GetAccessTokenResponse> {
    const { email, password, captcha } = params;

    const response = await this.api.post('/auth/login', {
      email,
      password,
      captcha,
    });

    const {
      token: accessToken,
      token_type: tokenType,
      expires,
    } = response.data.data;

    if (accessToken && tokenType) {
      this.addAccessTokenToCookie(accessToken, tokenType, expires);
    }

    return {
      accessToken,
      tokenType,
      expires,
    };
  }

  deleteAccessTokenFromCookie(): void {
    this.accessToken = undefined;
    this.tokenType = undefined;

    Cookies.remove('accessToken');
    Cookies.remove('tokenType');
    Cookies.remove('expires');
  }

  addAccessTokenToCookie(
    accessToken: string,
    tokenType: string,
    expires: number,
  ): void {
    this.accessToken = accessToken;
    this.tokenType = tokenType;
    this.expires = expires;

    Cookies.set('accessToken', accessToken, { expires: fromUnixTime(expires) });
    Cookies.set('tokenType', tokenType, { expires: fromUnixTime(expires) });
    Cookies.set('expires', expires.toString(), {
      expires: fromUnixTime(expires),
    });
  }
}

export default Auth;
