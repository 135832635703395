import { Loader } from 'rsuite';
import cn from 'classnames';
import styles from './loading.module.scss';

interface Props {
  isLoading?: boolean;
  vertical?: boolean;
  backdrop?: boolean;
  content?: string;
  isAsyncComponentLoad?: boolean;
}

const Loading = ({
  isLoading,
  vertical,
  backdrop = true,
  content = 'loading...',
  isAsyncComponentLoad = false,
}: Props) =>
  isLoading ? (
    <div
      className={cn(
        styles.container,
        isAsyncComponentLoad && styles.withMinHeight,
      )}
    >
      <Loader backdrop={backdrop} content={content} vertical={vertical} />
    </div>
  ) : null;

export default Loading;
