import {
  DataStoreItemInterface,
  DataStore,
  ListReturnableStore,
  DataStoreFilter,
} from 'shared/features/data-store/data-store.module';
import { StoreInterface } from 'shared/store/interfaces';
import { ResellerProductInterface } from './reseller-products.module';

class ResellerProductsStore extends DataStore implements ListReturnableStore {
  override list: ResellerProductInterface[] = [];

  constructor(rootStore: StoreInterface) {
    super({
      rootStore,
      apiClient: rootStore.apiClient.resellerProducts,
    });
  }

  async getList(
    filterParams?: DataStoreFilter,
  ): Promise<DataStoreItemInterface[] | undefined> {
    await this.fetch({
      page: 1,
      page_size: 999,
      status: 1,
      ...filterParams,
    });

    return this.list;
  }

  async getListAsDropdown(): Promise<any[] | []> {
    const data = await this.getList();

    if (data && data.length) {
      return (
        data
          .filter((el) => !el.name.includes('p2m'))
          .map((el) => ({
            key: el.id,
            value: el.id.toString(),
            text: el.name,
            id: el.id,
            name: el.name || el.short_name || '',
            status: el.status,
          })) || []
      );
    }

    return [];
  }
}

export default ResellerProductsStore;
