import { configure, makeAutoObservable } from 'mobx';
import BaseApiClient from 'shared/api';
import { ThemesStore } from 'shared/features/theme/themes.module';
import { AuthStore } from 'shared/features/auth/auth.module';
import { AppUserStore } from 'shared/features/app-user/app-user.module';
import { StatisticStore } from 'shared/features/statistic/statistic.module';
import { DictionariesStore } from 'shared/features/dictionaries/dictionaries.module';
import { ResellerProductsStore } from 'shared/features/reseller-products/reseller-products.module';
import { NotificationsStore } from 'shared/features/notifications/notifications.module';
import { PaymentSystemsStore } from 'shared/features/payment-systems/payment-systems.module';
import { BillingsStore } from 'shared/features/billings/billings.module';
import { MerchantsStore } from 'shared/features/merchants/merchants.module';
import { ResellerInvoicesStore } from 'shared/features/reseller-invoices/reseller-invoices.module';
import SupportStore from 'shared/features/support/support.store';
import LayoutStore from 'shared/features/layout/layout.store';
import { UpgradeNotificationsStore } from 'shared/features/upgrade-notifications/upgrade-notifications.module';

configure({ enforceActions: 'observed' });

export class SharedRootStore {
  readonly authStore: AuthStore;

  readonly appUserStore: AppUserStore;

  readonly resellerProductsStore: ResellerProductsStore;

  supportStore: SupportStore;

  statisticStore: StatisticStore;

  dictionariesStore: DictionariesStore;

  resellerInvoicesStore: ResellerInvoicesStore;

  paymentSystemsStore: PaymentSystemsStore;

  billingsStore: BillingsStore;

  merchantsStore: MerchantsStore;

  notificationsStore: NotificationsStore;

  upgradeNotificationsStore: UpgradeNotificationsStore;

  layoutStore: LayoutStore;

  constructor(
    readonly apiClient = new BaseApiClient(),
    readonly stores = {
      theme: new ThemesStore(),
    },
  ) {
    makeAutoObservable(this);
    this.authStore = new AuthStore(this);
    this.appUserStore = new AppUserStore(this);
    this.statisticStore = new StatisticStore(this);
    this.notificationsStore = new NotificationsStore();
    this.upgradeNotificationsStore = new UpgradeNotificationsStore();
    this.supportStore = new SupportStore(this);
    this.dictionariesStore = new DictionariesStore(this);
    this.resellerProductsStore = new ResellerProductsStore(this);
    this.paymentSystemsStore = new PaymentSystemsStore(this);
    this.billingsStore = new BillingsStore(this);
    this.merchantsStore = new MerchantsStore(this);
    this.resellerInvoicesStore = new ResellerInvoicesStore(this);
    this.layoutStore = new LayoutStore();

    this.reset = this.reset.bind(this);
  }

  reset(): void {
    this.appUserStore.resetUser();
    this.statisticStore = new StatisticStore(this);
    this.dictionariesStore = new DictionariesStore(this);
    this.notificationsStore = new NotificationsStore();
    this.upgradeNotificationsStore = new UpgradeNotificationsStore();
    this.billingsStore = new BillingsStore(this);
    this.merchantsStore = new MerchantsStore(this);
    this.paymentSystemsStore = new PaymentSystemsStore(this);
    this.resellerInvoicesStore = new ResellerInvoicesStore(this);
    this.layoutStore = new LayoutStore();
  }
}

export default SharedRootStore;
