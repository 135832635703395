/* eslint-disable no-underscore-dangle */
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import Cookies from 'js-cookie';
import { ErrorInterface, ExtendedErrorInterface } from 'shared/api/interfaces';
import qs from 'qs';
import { serialize } from 'object-to-formdata';
import { AuthService } from '../features/auth/auth.module';

export interface FailedQueue {
  resolve: (value: unknown) => void;
  reject: (reason?: string) => void;
}

/**
 * Проверка конфига запроса на то, что это запрос авторизации
 * @param config
 * @returns {boolean}
 */
const isLoginRequest = (config: AxiosRequestConfig): boolean => {
  if (config.method !== 'post') {
    return false;
  }

  return !!(config && config.url && config.url.includes('/auth/login'));
};

export const getErrorMessageWithCode = (
  err: AxiosError,
): ExtendedErrorInterface => {
  if (err.response) {
    switch (err.response.status) {
      case 400:
        return {
          text: 'Bad request',
          code: err.response.status,
        };
      case 401:
        return {
          text: 'Unauthorized',
          code: err.response.status,
        };
      case 403:
        return {
          text: 'Forbidden',
          code: err.response.status,
        };
      case 404:
        return {
          text: 'Not found',
          code: err.response.status,
        };
      default:
        return {
          text: 'Error',
          code: err.response.status,
        };
    }
  } else if (err.request) {
    return {
      text: 'Connection refused',
      code: 0,
    };
  }

  return {
    text: 'Server error',
    code: -1,
  };
};

/**
 * Универсальный парсер ошибок axios запросов
 * @param err
 * @returns {DetailsErrorInterface}
 */
export const getErrorMessage = (err: AxiosError): string => {
  if (err.response) {
    switch (err.response.status) {
      case 400:
        return 'Bad request';
      case 401:
        return 'Unauthorized';
      case 403:
        return 'Forbidden';
      case 404:
        return 'Not found';
      default:
        return 'Error';
    }
  } else if (err.request) {
    return 'Connection refused';
  }

  return 'Server error';
};

/**
 * Универсальный парсер ошибок axios запросов
 * @param err
 * @returns {string}
 */
export const getErrors = (err: AxiosError<any>): ErrorInterface => {
  if (err.response) {
    if (err.response.data.error_message) {
      if (typeof err.response.data.error_message === 'object') {
        return err.response.data.error_message;
      }

      return {
        server: err.response.data.error_message,
      };
    }

    if (err.response.status === 400) {
      return {
        server: 'Bad request',
      };
    }

    if (err.response.status === 401) {
      return {
        server: 'Unauthorized',
      };
    }

    if (err.response.status === 404) {
      return {
        server: 'Not found',
      };
    }

    if (err.response.status === 403) {
      return {
        server: 'Forbidden',
      };
    }
  } else if (err.request) {
    return {
      server: 'Connection refused',
    };
  }

  return {
    server: 'Server error',
  };
};

/**
 * Base Api connector class
 * only initialization of API connect and AUTH here
 *  */
class BaseApiClient {
  readonly api: AxiosInstance;

  readonly auth;

  isRefreshing = false;

  failedQueue = [] as FailedQueue[];

  constructor({
    baseURL = process.env.REACT_APP_API_BASE_URL,
    timeout = 0,
  }: {
    baseURL?: string;
    timeout?: number | 0;
  } = {}) {
    if (!baseURL) {
      throw new Error('Missing API baseURL');
    }
    this.api = axios.create({
      baseURL,
      timeout,
      paramsSerializer: {
        serialize: (params: Record<string, any>) => qs.stringify(params),
      },
    } as AxiosRequestConfig);

    this.auth = new AuthService(this.api);

    this.api.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => ({
        ...this.transformRequest(config),
      }),
      (err) => Promise.reject(err),
    );

    this.api.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (err) => {
        if (err.response?.status === 401) {
          this.auth.deleteAccessTokenFromCookie();
        }

        return Promise.reject(err);
      },
    );
  }

  /**
   * Метод обработки зафейленых запросов
   * @param error
   * @param token
   * @returns void
   */
  processQueue(
    error: string | null,
    token = null as null | string | undefined,
  ): void {
    this.failedQueue.forEach((promise) => {
      if (error) {
        promise.reject(error);
      } else {
        promise.resolve(token);
      }
    });

    this.failedQueue = [];
  }

  /**
   * Метод добавляет content-type и форматирует тело post и put запроса в json.
   * @param axiosConfig
   * @returns {InternalAxiosRequestConfig}
   */
  transformRequest(
    axiosConfig: InternalAxiosRequestConfig,
  ): InternalAxiosRequestConfig {
    const { data, headers } = axiosConfig;
    const accessToken = Cookies.get('accessToken');
    const tokenType = Cookies.get('tokenType');

    if (isLoginRequest(axiosConfig)) {
      return {
        ...axiosConfig,
        headers: {
          ...headers,
          'Content-Type': 'application/json',
        } as AxiosRequestHeaders,
        data,
      };
    }

    if (accessToken && tokenType) {
      const hasImage =
        (data && Object.keys(data).find((el: any) => el === 'image')) || false;

      if (hasImage) {
        const formData = serialize(data, {
          indices: true,
          noFilesWithArrayNotation: true,
        });

        return {
          ...axiosConfig,
          headers: {
            ...headers,
            'Content-Type': 'multipart/form-data',
            Authorization: `${tokenType} ${accessToken}`,
          } as AxiosRequestHeaders,
          data: formData,
        };
      }

      return {
        ...axiosConfig,
        headers: {
          ...headers,
          'Content-Type': 'application/json',
          Authorization: `${tokenType} ${accessToken}`,
        } as AxiosRequestHeaders,
        data,
      };
    }

    return axiosConfig;
  }
}

export default BaseApiClient;
