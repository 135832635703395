import { ThemeObject } from 'reseller/interfaces';
import { THEMES } from '../constants';

const lightTheme: ThemeObject = {
  name: THEMES.LIGHT,
  rules: new Map<string, string>(),
};

lightTheme.rules.set('--main-background', '#ececec');
lightTheme.rules.set('--semi-background', '#f9fafb');
lightTheme.rules.set('--main-dark', '#868686');
lightTheme.rules.set('--main-dark-01', 'rgba(53, 63,83, .1)');
lightTheme.rules.set('--main-dark-border', '#3e4c5d');
lightTheme.rules.set('--hover-blue', '#03a9f4');
lightTheme.rules.set('--menu-active', '#03a9f4');
lightTheme.rules.set('--main-text', '#ffffff');
lightTheme.rules.set('--dark-text-09', 'rgba(255,255,255,.9)');
lightTheme.rules.set('--dark-text-03', 'rgba(255,255,255,.3)');
lightTheme.rules.set('--dark-text-01', 'rgba(255,255,255,.1)');

export default lightTheme;
